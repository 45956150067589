let Environment = {
  api_url: "https://mainnet-api-prod.dop.org",
  // api_url: "http://ec2-54-191-120-95.us-west-2.compute.amazonaws.com:8082",
  // api_url: ""https://mainnet-api.dop.org",

  // ethhereum 
  Usdc:'0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
  Usdt:'0xdAC17F958D2ee523a2206206994597C13D831ec7',
  Weth:'0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
  Wbth:'0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
  Dop:"0x97a9a15168c22b3c137e6381037e1499c8ad0978",
  PEPE: "0x6982508145454ce325ddbe47a25d4ec3d2311933",
  chainLink: "0x514910771af9ca656af840dff83e8264ecf986ca",
  UNI: "0x1f9840a85d5af5bf1d1762f925bdaddc4201f984",
  RNDR: "0x6de037ef9ad2725eb40118bb1702ebb27e4aeb24",
  SHIBA: "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
  LIDO: "0x5a98fcbea516cf06857215779fd812ca3bef1b32",
  FLOKI: "0xcf0c122c6b73ff809c693db761e7baebe62b6a2e",
  DAI: "0x6b175474e89094c44da98b954eedeac495271d0f",
  AVAX: "0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9",
  ENS: "0xc18360217d8f7ab5e7c516566761ea12ce7f9d72",
  CHZ: "0x3506424f91fd33084466f402d5d97f05f8e3b4af",
  MANA: "0x0f5d2fb29fb7d3cfee444a200298f468908cc942",
  wMatic: "0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0",
  wPol: "0x455e53CBB86018Ac2B8092FdCd39d8444aFFC3F6",

  // polygon
  Polygonusdt: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
  PolygonUSDC: "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359",
  // PolygonwBTC: "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
  // PolygonCoinW: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
  PolygonchainLink: "0x53e0bca35ec356bd5dddfebbd1fc0fd03fabad39",
  PolygonUNI: "0xb33eaad8d922b1083446dc23f610c2567fb5180f",
  PolygonRNDR: "0x61299774020dA444Af134c82fa83E3810b309991",
  PolygonSHIBA: "0x6f8a06447ff6fcf75d803135a7de15ce88c1d4ec",
  PolygonLIDO: "0xC3C7d422809852031b44ab29EEC9F1EfF2A58756",
  PolygonDAI: "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
  PolygonAVAX: "0xd6df932a45c0f255f85145f286ea0b292b21c90b",
  PolygonCHZ: "0xf1938Ce12400f9a761084E7A80d37e732a4dA056",
  PolygonMANA: "0xa1c57f48f0deb89f569dfbe6e2b7f46d33606fd4",
  PolygonMatic: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",


  // Xlayer
  Xlayerusdt: "0x1e4a5963abfd975d8c9021ce480b42188849d41d",
  XlayerUSDC: "0x74b7f16337b8972027f6196a17a631ac6de26d22",
  XlayerwBTC: "0xea034fb02eb1808c2cc3adbc15f447b93cbe08e1",
  XlayerwETH: "0x5a77f1443d16ee5761d310e38b62f77f726bc71c",
  XlayerwMATIC: "0xa2036f0538221a77a3937f1379699f44945018d0",
  XlayerCoinW: "0xe538905cf8410324e03a5a23c1c177a474d59b2b",
  XlayerSHIBA: "0xf067edccfce94d4480486255cad9b8f48a39d58d",
  XlayerDAI: "0xc5015b9d9161dca7e18e32f6f25c4ad850731fd4",
  XlayerWokb: "0xe538905cf8410324e03a5a23c1c177a474d59b2b",
};

export default Environment;


