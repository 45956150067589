import Web3 from 'web3'
import { SUPPORTED_CHAINS } from './chains'
import { getNodeUrl } from './getRpcUrl'

const web3Instances = {}

export const getWeb3NoAccount = (chainId = SUPPORTED_CHAINS.ETHEREUM) => {
  if (!web3Instances[chainId]) {
    const RPC_URL = getNodeUrl(chainId)
    const httpProvider = new Web3.providers.HttpProvider(RPC_URL, { timeout: 10000 })
    web3Instances[chainId] = new Web3(httpProvider)
  }
  return web3Instances[chainId]
}
