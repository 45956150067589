
import UsdcAbi from "../utils/UsdcAbi.json";
import DopAbi from "../utils/DopAbi.json";
import PolygonUsdtAbi from "../utils/PolygonUsdtAbi.json";
import { getWeb3NoAccount } from "./web3";

const getContract = (abi, address, web3) => {
  const _web3 = web3 ?? getWeb3NoAccount;
  // console.log('_web3',_web3);
  return new _web3.eth.Contract(abi, address);

};

export const getUsdcContract = (address, web3) => {
  return getContract(UsdcAbi, address, web3);
};
export const getPolygonContract = (address, web3) => {
  return getContract(PolygonUsdtAbi, address, web3);
};


export const getDopContract = (address, web3) => {
  return getContract(DopAbi, address, web3);
};

