import React, { useState, useEffect } from 'react'
import "./banner.scss"
import Header from '../Header/Header';
import { DateRange } from "react-date-range";
import axios from 'axios';
import moment from 'moment/moment';
import useWeb3 from '../../../hooks/useWeb3';
import { getUsdcContract, getDopContract, getPolygonContract } from '../../../utils/contractHelpers';
import Environment from '../../../utils/Enviroment';
import ValueLockedGraph from './ValueLockedGraph';
import Countrychart from './CountryChart';
import Datatable from './Datatable/Datatable';
import getNodeUrl from '../../../utils/getRpcUrl';
import { useNavigate } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { useNetworks } from '../../NetworkContext';
import { SUPPORTED_CHAINS } from '../../../utils/chains';

const Banner = () => {
  const { selectedNetworks } = useNetworks();
  const [state, setState] = useState({ startDate: new Date(), endDate: new Date(), key: 'selection' });
  const [filterDates, setFilterDates] = useState({ startDate: new Date(), endDate: new Date() });
  const api_url = Environment.api_url;
  const token = localStorage.getItem("accessToken");
  const [alllset, setalllset] = useState("1");
  const [dataTime, setDataTime] = useState("1");
  const [showcalendar, setShowCalendar] = useState(false);
  const [admindata, setAdminData] = useState([]);
  const [usersdata, setUsersData] = useState([]);
  const [usersencrypt, setUsersEncrypts] = useState([]);
  const [userssends, setUsersSends] = useState([]);
  const [userspoints, setUsersPoints] = useState([]);
  const [countries, setCountries] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [tvlgraph, setTvlGraph] = useState([]);
  const [countriesgraph, setCountriesGraph] = useState([]);
  const [decryptData, setDecryptData] = useState(null);
  const [encryptData, setEncryptData] = useState(null);
  const [loader, setLoader] = useState(false);
  const [loadertvl, setLoaderTvl] = useState(false);
  const [loadertransaction, setLoaderTransaction] = useState(false);
  const itemsPerPage = 10;
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState([]);
  const web3 = useWeb3();
  const web3Polygon = useWeb3(SUPPORTED_CHAINS.POLYGON)
  const web3layer = useWeb3(SUPPORTED_CHAINS.Xlayer)

  // const nodeurl = getNodeUrl()
  const navigate = useNavigate()
  const polygonstakingAddress1 = Environment.Polygonusdt;
  const polygonstakingAddress2 = Environment.PolygonUSDC;
  // const polygonstakingAddress3 = Environment.PolygonwBTC;
  // const polygonstakingAddress4 = Environment.PolygonCoinW;
  const polygonstakingAddress5 = Environment.PolygonchainLink;
  const polygonstakingAddress6 = Environment.PolygonUNI;
  const polygonstakingAddress7 = Environment.PolygonRNDR;
  const polygonstakingAddress8 = Environment.PolygonSHIBA;
  const polygonstakingAddress9 = Environment.PolygonLIDO;
  const polygonstakingAddress10 = Environment.PolygonDAI;
  const polygonstakingAddress11 = Environment.PolygonAVAX;
  const polygonstakingAddress12 = Environment.PolygonCHZ;
  const polygonstakingAddress13 = Environment.PolygonMANA;
  const polygonstakingAddress14 = Environment.PolygonMatic;
  const stakingAddress1 = Environment.Usdc;
  const stakingAddress2 = Environment.Usdt;
  const stakingAddress3 = Environment.Wbth;
  const stakingAddress4 = Environment.Weth;
  const stakingAddress5 = Environment.Dop;
  const stakingAddress6 = Environment.PEPE;
  const stakingAddress7 = Environment.chainLink;
  const stakingAddress8 = Environment.UNI;
  const stakingAddress9 = Environment.RNDR;
  const stakingAddress10 = Environment.SHIBA;
  const stakingAddress11 = Environment.LIDO;
  const stakingAddress12 = Environment.FLOKI;
  const stakingAddress13 = Environment.DAI;
  const stakingAddress14 = Environment.AVAX;
  const stakingAddress15 = Environment.ENS;
  const stakingAddress16 = Environment.CHZ;
  const stakingAddress17 = Environment.MANA;
  const stakingAddress18 = Environment.wMatic;
  const stakingAddress19 = Environment.wPol;
  const xlayerAddress1 = Environment.Xlayerusdt;
  const xlayerAddress2 = Environment.XlayerUSDC;
  const xlayerAddress3 = Environment.XlayerwBTC;
  const xlayerAddress4 = Environment.XlayerwETH;
  const xlayerAddress5 = Environment.XlayerwMATIC;
  const xlayerAddress6 = Environment.XlayerCoinW;
  const xlayerAddress7 = Environment.XlayerSHIBA;
  const xlayerAddress8 = Environment.XlayerDAI;
  const xlayerAddress9 = Environment.XlayerWokb;
  // const stakingAddress20 = Environment.router;
  const contract1 = getUsdcContract(stakingAddress1, web3);
  const contract2 = getUsdcContract(stakingAddress2, web3);
  const contract3 = getUsdcContract(stakingAddress3, web3);
  const contract4 = getUsdcContract(stakingAddress4, web3);
  const contract5 = getDopContract(stakingAddress5, web3);
  const contract6 = getUsdcContract(stakingAddress6, web3);
  const contract7 = getUsdcContract(stakingAddress7, web3);
  const contract8 = getUsdcContract(stakingAddress8, web3);
  const contract9 = getUsdcContract(stakingAddress9, web3);
  const contract10 = getDopContract(stakingAddress10, web3);
  const contract11 = getUsdcContract(stakingAddress11, web3);
  const contract12 = getUsdcContract(stakingAddress12, web3);
  const contract13 = getUsdcContract(stakingAddress13, web3);
  const contract14 = getUsdcContract(stakingAddress14, web3);
  const contract15 = getDopContract(stakingAddress15, web3);
  const contract16 = getUsdcContract(stakingAddress16, web3);
  const contract17 = getUsdcContract(stakingAddress17, web3);
  const contract18 = getUsdcContract(stakingAddress18, web3);
  const contract19 = getUsdcContract(stakingAddress19, web3);
  // const contract20 = getDopContract(stakingAddress20, web3);
  const polygoncontract1 = getPolygonContract(polygonstakingAddress1, web3Polygon);
  const polygoncontract2 = getPolygonContract(polygonstakingAddress2, web3Polygon);
  // const polygoncontract3 = getUsdcContract(polygonstakingAddress3, web3);
  // const polygoncontract4 = getUsdcContract(polygonstakingAddress4, web3);
  const polygoncontract5 = getPolygonContract(polygonstakingAddress5, web3Polygon);
  const polygoncontract6 = getPolygonContract(polygonstakingAddress6, web3Polygon);
  const polygoncontract7 = getPolygonContract(polygonstakingAddress7, web3Polygon);
  const polygoncontract8 = getPolygonContract(polygonstakingAddress8, web3Polygon);
  const polygoncontract9 = getPolygonContract(polygonstakingAddress9, web3Polygon);
  const polygoncontract10 = getPolygonContract(polygonstakingAddress10, web3Polygon);
  const polygoncontract11 = getUsdcContract(polygonstakingAddress11, web3Polygon);
  const polygoncontract12 = getPolygonContract(polygonstakingAddress12, web3Polygon);
  const polygoncontract13 = getPolygonContract(polygonstakingAddress13, web3Polygon);
  const polygoncontract14 = getPolygonContract(polygonstakingAddress14, web3Polygon);
  const xlayercontract1 = getUsdcContract(xlayerAddress1, web3layer);
  const xlayercontract2 = getUsdcContract(xlayerAddress2, web3layer);
  const xlayercontract3 = getUsdcContract(xlayerAddress3, web3layer);
  const xlayercontract4 = getUsdcContract(xlayerAddress4, web3layer);
  const xlayercontract5 = getUsdcContract(xlayerAddress5, web3layer);
  const xlayercontract6 = getUsdcContract(xlayerAddress6, web3layer);
  const xlayercontract7 = getUsdcContract(xlayerAddress7, web3layer);
  const xlayercontract8 = getUsdcContract(xlayerAddress8, web3layer);
  const xlayercontract9 = getUsdcContract(xlayerAddress9, web3layer);
  const [version, setVersion] = useState('');


  const getAdminData = async (times) => {
    if (times && times?.[0]?.startDate && times?.[0]?.endDate) {
      const startDate = moment(times[0].startDate).utc().startOf('day').format("YYYY-MM-DDTHH:mm:ss[Z]");
      const endDate = moment(times[0].endDate).utc().endOf('day').format("YYYY-MM-DDTHH:mm:ss[Z]");
      const dynamicQuery = `?startDate=${startDate}&endDate=${endDate}`;

      try {
        const response = await axios.get(`${api_url}/admins/admin-data${dynamicQuery}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setAdminData(response?.data?.data);
      } catch (err) {
        console.error('Error fetching influencers:', err);
      }
    }
  };
  useEffect(() => {
    const today = new Date();
    const specificDateRange = [
      {
        startDate: new Date(today),
        endDate: new Date(today),
      },
    ];
    getAdminData(specificDateRange, state);
  }, []);

  // eth v1 
  let address = "0x9A3152b61420ed4D5e594c0b48bB932eE41B7376"
  // eth v2
  let address2 = "0x100063491aFD897b58828B3d4FE738Ab979E13Df"
  // polygon v1 
  let polygonaddress = "0x8593da65D55cCDb9367377231925D0688EFb5007"
  // polygon v2
  let polygonaddress2 = "0x100063491aFD897b58828B3d4FE738Ab979E13Df"
  // xlayer v1 
  let xlayeraddress = "0xC74FF5452f19825856642524dE8B01e8B83e93c6"
  // xlayer v2
  let xlayeraddress2 = "0x100063491aFD897b58828B3d4FE738Ab979E13Df"
  const [usdcvalue, getUsdcValue] = useState(0)
  const [usdtvalue, getUsdtValue] = useState(0)
  const [wethvalue, getWethValue] = useState(0)
  const [wbthvalue, getWbthValue] = useState(0)
  const [dopvalue, getDopValue] = useState(0)
  const [pepevalue, getPepeValue] = useState(0)
  const [chainlink, getChainLink] = useState(0)
  const [uniswap, getUniSwap] = useState(0)
  const [rendertoken, getRenderToken] = useState(0)
  const [shibainu, getShibaInu] = useState(0)
  const [lidodao, getLidoDao] = useState(0)
  const [floki, getFloki] = useState(0)
  const [dai, getDai] = useState(0)
  const [aave, getAavi] = useState(0)
  const [ethservice, getEthService] = useState(0)
  const [chiliz, getChiliz] = useState(0)
  const [mana, getMana] = useState(0)
  const [maticvalue, getMaticValue] = useState(0)
  const [polygoneco, getPolygonEco] = useState(0)
  const [okb, getOkb] = useState(0)
  const [sumofall, getSumofall] = useState(0)
  const [sumofall2, getSumofall2] = useState(0)
  const [sumOfPolygon, getSumOfPolygon] = useState(0);
  const [sumOfPolygon2, getSumOfPolygon2] = useState(0);
  const [xlayer, getXlayer] = useState(0);
  const [xlayer2, getXlayer2] = useState(0);
  useEffect(() => {
    if (!usdtvalue) {
      axios
        .get(api_url + "/transactions/get-price?coinId=tether")
        .then((res) => {
          getUsdtValue(res?.data?.data?.market_data?.current_price?.usd);
        });
    }
    if (!wethvalue) {
      axios
        .get(api_url + "/transactions/get-price?coinId=ethereum")
        .then((res) => {
          getWethValue(res?.data?.data?.market_data?.current_price?.usd);
        });
    }
    if (!wbthvalue) {
      axios
        .get(api_url + "/transactions/get-price?coinId=wrapped-bitcoin")
        .then((res) => {
          getWbthValue(res?.data?.data?.market_data?.current_price?.usd);
        });
    }
    if (!usdcvalue) {
      axios
        .get(api_url + "/transactions/get-price?coinId=usd-coin")
        .then((res) => {
          getUsdcValue(res?.data?.data?.market_data?.current_price?.usd);
        });
    }
    if (!dopvalue) {
      axios
        .get(api_url + "/transactions/get-price?coinId=data-ownership-protocol")
        .then((res) => {
          getDopValue(res?.data?.data?.market_data?.current_price?.usd);
        });
    }

    if (!pepevalue) {
      axios
        .get(api_url + "/transactions/get-price?coinId=pepe")
        .then((res) => {
          getPepeValue(res?.data?.data?.market_data?.current_price?.usd);
        });
    }
    if (!chainlink) {
      axios
        .get(api_url + "/transactions/get-price?coinId=chainlink")
        .then((res) => {
          getChainLink(res?.data?.data?.market_data?.current_price?.usd);
        });
    }
    if (!uniswap) {
      axios
        .get(api_url + "/transactions/get-price?coinId=uniswap")
        .then((res) => {
          getUniSwap(res?.data?.data?.market_data?.current_price?.usd);
        });
    }
    if (!rendertoken) {
      axios
        .get(api_url + "/transactions/get-price?coinId=render-token")
        .then((res) => {
          getRenderToken(res?.data?.data?.market_data?.current_price?.usd);
        });
    }
    if (!shibainu) {
      axios
        .get(api_url + "/transactions/get-price?coinId=shiba-inu")
        .then((res) => {
          getShibaInu(res?.data?.data?.market_data?.current_price?.usd);
        });
    }
    if (!lidodao) {
      axios
        .get(api_url + "/transactions/get-price?coinId=lido-dao")
        .then((res) => {
          getLidoDao(res?.data?.data?.market_data?.current_price?.usd);
        });
    }
    if (!floki) {
      axios
        .get(api_url + "/transactions/get-price?coinId=floki")
        .then((res) => {
          getFloki(res?.data?.data?.market_data?.current_price?.usd);
        });
    } if (!dai) {
      axios
        .get(api_url + "/transactions/get-price?coinId=dai")
        .then((res) => {
          getDai(res?.data?.data?.market_data?.current_price?.usd);
        });
    }
    if (!aave) {
      axios
        .get(api_url + "/transactions/get-price?coinId=aave")
        .then((res) => {
          getAavi(res?.data?.data?.market_data?.current_price?.usd);
        });
    }
    if (!ethservice) {
      axios
        .get(api_url + "/transactions/get-price?coinId=ethereum-name-service")
        .then((res) => {
          getEthService(res?.data?.data?.market_data?.current_price?.usd);
        });
    }
    if (!chiliz) {
      axios
        .get(api_url + "/transactions/get-price?coinId=chiliz")
        .then((res) => {
          getChiliz(res?.data?.data?.market_data?.current_price?.usd);
        });
    }
    if (!mana) {
      axios
        .get(api_url + "/transactions/get-price?coinId=decentraland")
        .then((res) => {
          getMana(res?.data?.data?.market_data?.current_price?.usd);
        });
    }
    if (!maticvalue) {
      axios
        .get(api_url + "/transactions/get-price?coinId=matic-network")
        .then((res) => {
          getMaticValue(res?.data?.data?.market_data?.current_price?.usd);
        });
    }
    if (!polygoneco) {
      axios
        .get(api_url + "/transactions/get-price?coinId=polygon-ecosystem-token")
        .then((res) => {
          getPolygonEco(res?.data?.data?.market_data?.current_price?.usd);
        });
    }
    if (!okb) {
      axios
        .get(api_url + "/transactions/get-price?coinId=okb")
        .then((res) => {
          getOkb(res?.data?.data?.market_data?.current_price?.usd);
        });
    }

  }, []);

  const getPlatformFee = async () => {
    try {
      const [staked1, staked2, staked3, staked4, staked5, staked6, staked7, staked8, staked9, staked10, staked11
        , staked12
        , staked13, staked14, staked15, staked16, staked17, staked18, staked19] = await Promise.all([
          contract1?._methods?.balanceOf(address)?.call(),
          contract2?._methods?.balanceOf(address)?.call(),
          contract3?._methods?.balanceOf(address)?.call(),
          contract4?._methods?.balanceOf(address)?.call(),
          contract5?._methods?.balanceOf(address)?.call(),
          contract6?._methods?.balanceOf(address)?.call(),
          contract7?._methods?.balanceOf(address)?.call(),
          contract8?._methods?.balanceOf(address)?.call(),
          contract9?._methods?.balanceOf(address)?.call(),
          contract10?._methods?.balanceOf(address)?.call(),
          contract11?._methods?.balanceOf(address)?.call(),
          contract12?._methods?.balanceOf(address)?.call(),
          contract13?._methods?.balanceOf(address)?.call(),
          contract14?._methods?.balanceOf(address)?.call(),
          contract15?._methods?.balanceOf(address)?.call(),
          contract16?._methods?.balanceOf(address)?.call(),
          contract17?._methods?.balanceOf(address)?.call(),
          contract18?._methods?.balanceOf(address)?.call(),
          contract19?._methods?.balanceOf(address)?.call(),
        ]);
      const staked1Converted = web3.utils.fromWei(staked1, 'mwei'); // 6 decimals for USDC
      const staked2Converted = web3.utils.fromWei(staked2, 'mwei'); // 6 decimals for USDT
      const staked3Converted = web3.utils.fromWei(staked3, 'gwei'); // 8 decimals for WBTH
      const staked4Converted = web3.utils.fromWei(staked4, 'ether'); // 18 decimals for WETH
      const staked5Converted = web3.utils.fromWei(staked5, 'ether');
      const staked6Converted = web3.utils.fromWei(staked6, 'ether');
      const staked7Converted = web3.utils.fromWei(staked7, 'ether');
      const staked8Converted = web3.utils.fromWei(staked8, 'ether');
      const staked9Converted = web3.utils.fromWei(staked9, 'ether');
      const staked10Converted = web3.utils.fromWei(staked10, 'ether');
      const staked11Converted = web3.utils.fromWei(staked11, 'ether');
      const staked12Converted = web3.utils.fromWei(staked12, 'gwei');  // 9 decimal points
      const staked13Converted = web3.utils.fromWei(staked13, 'ether');
      const staked14Converted = web3.utils.fromWei(staked14, 'ether');
      const staked15Converted = web3.utils.fromWei(staked15, 'ether');
      const staked16Converted = web3.utils.fromWei(staked16, 'ether');
      const staked17Converted = web3.utils.fromWei(staked17, 'ether');
      const staked18Converted = web3.utils.fromWei(staked18, 'ether');
      const staked19Converted = web3.utils.fromWei(staked19, 'ether');
      let finalsum =
        (staked1Converted * usdcvalue) + (staked2Converted * usdtvalue) +
        (staked3Converted * wbthvalue) + (staked4Converted * wethvalue) +
        (staked5Converted * dopvalue) + (staked6Converted * pepevalue) +
        (staked7Converted * chainlink) + (staked8Converted * uniswap) +
        (staked9Converted * rendertoken) + (staked10Converted * shibainu) +
        (staked11Converted * lidodao) +
        (staked12Converted * shibainu) +
        (staked13Converted * dai) + (staked14Converted * aave) +
        (staked15Converted * ethservice) + (staked16Converted * chiliz) +
        (staked17Converted * mana) + (staked18Converted * maticvalue) +
        (staked19Converted * polygoneco)
      getSumofall(finalsum)
    } catch (error) {
      console.error("Error in getPlatformFee:", error);
      throw error;
    }
  };
  const getPlatformFee2 = async () => {
    try {
      const [staked1, staked2, staked3, staked4, staked5, staked6, staked7, staked8, staked9, staked10, staked11
        , staked12
        , staked13, staked14, staked15, staked16, staked17, staked18, staked19] = await Promise.all([
          contract1?._methods?.balanceOf(address2)?.call(),
          contract2?._methods?.balanceOf(address2)?.call(),
          contract3?._methods?.balanceOf(address2)?.call(),
          contract4?._methods?.balanceOf(address2)?.call(),
          contract5?._methods?.balanceOf(address2)?.call(),
          contract6?._methods?.balanceOf(address2)?.call(),
          contract7?._methods?.balanceOf(address2)?.call(),
          contract8?._methods?.balanceOf(address2)?.call(),
          contract9?._methods?.balanceOf(address2)?.call(),
          contract10?._methods?.balanceOf(address2)?.call(),
          contract11?._methods?.balanceOf(address2)?.call(),
          contract12?._methods?.balanceOf(address2)?.call(),
          contract13?._methods?.balanceOf(address2)?.call(),
          contract14?._methods?.balanceOf(address2)?.call(),
          contract15?._methods?.balanceOf(address2)?.call(),
          contract16?._methods?.balanceOf(address2)?.call(),
          contract17?._methods?.balanceOf(address2)?.call(),
          contract18?._methods?.balanceOf(address2)?.call(),
          contract19?._methods?.balanceOf(address2)?.call(),
        ]);
      const staked1Converted = web3.utils.fromWei(staked1, 'mwei'); // 6 decimals for USDC
      const staked2Converted = web3.utils.fromWei(staked2, 'mwei'); // 6 decimals for USDT
      const staked3Converted = web3.utils.fromWei(staked3, 'gwei'); // 8 decimals for WBTH
      const staked4Converted = web3.utils.fromWei(staked4, 'ether'); // 18 decimals for WETH
      const staked5Converted = web3.utils.fromWei(staked5, 'ether');
      const staked6Converted = web3.utils.fromWei(staked6, 'ether');
      const staked7Converted = web3.utils.fromWei(staked7, 'ether');
      const staked8Converted = web3.utils.fromWei(staked8, 'ether');
      const staked9Converted = web3.utils.fromWei(staked9, 'ether');
      const staked10Converted = web3.utils.fromWei(staked10, 'ether');
      const staked11Converted = web3.utils.fromWei(staked11, 'ether');
      const staked12Converted = web3.utils.fromWei(staked12, 'gwei');  // 9 decimal points
      const staked13Converted = web3.utils.fromWei(staked13, 'ether');
      const staked14Converted = web3.utils.fromWei(staked14, 'ether');
      const staked15Converted = web3.utils.fromWei(staked15, 'ether');
      const staked16Converted = web3.utils.fromWei(staked16, 'ether');
      const staked17Converted = web3.utils.fromWei(staked17, 'ether');
      const staked18Converted = web3.utils.fromWei(staked18, 'ether');
      const staked19Converted = web3.utils.fromWei(staked19, 'ether');
      let finalsum =
        (staked1Converted * usdcvalue) + (staked2Converted * usdtvalue) +
        (staked3Converted * wbthvalue) + (staked4Converted * wethvalue) +
        (staked5Converted * dopvalue) + (staked6Converted * pepevalue) +
        (staked7Converted * chainlink) + (staked8Converted * uniswap) +
        (staked9Converted * rendertoken) + (staked10Converted * shibainu) +
        (staked11Converted * lidodao) +
        (staked12Converted * shibainu) +
        (staked13Converted * dai) + (staked14Converted * aave) +
        (staked15Converted * ethservice) + (staked16Converted * chiliz) +
        (staked17Converted * mana) + (staked18Converted * maticvalue) +
        (staked19Converted * polygoneco)
      getSumofall2(finalsum)
    } catch (error) {
      console.error("Error in getPlatformFee:", error);
      throw error;
    }
  };
  // const getPlatformFee2 = async () => {
  //   try {
  //     const [staked1, staked2, staked3, staked4, staked5] = await Promise.all([
  //       contract1?._methods?.balanceOf(address2)?.call(),
  //       contract2?._methods?.balanceOf(address2)?.call(),
  //       contract3?._methods?.balanceOf(address2)?.call(),
  //       contract4?._methods?.balanceOf(address2)?.call(),
  //       contract5?._methods?.balanceOf(address2)?.call(),
  //     ]);
  //     const staked1Converted = web3.utils.fromWei(staked1, 'mwei'); // 6 decimals for USDC
  //     const staked2Converted = web3.utils.fromWei(staked2, 'mwei'); // 6 decimals for USDT
  //     const staked3Converted = web3.utils.fromWei(staked3, 'gwei'); // 8 decimals for WBTH
  //     const staked4Converted = web3.utils.fromWei(staked4, 'ether'); // 18 decimals for WETH
  //     const staked5Converted = web3.utils.fromWei(staked5, 'ether');
  //     let finalsum = (staked1Converted * usdcvalue) + (staked2Converted * usdtvalue) + (staked3Converted * wbthvalue) + (staked4Converted * wethvalue) + (staked5Converted * dopvalue)
  //     getSumofall2(finalsum)
  //   } catch (error) {
  //     console.error("Error in getPlatformFee:", error);
  //     throw error;
  //   }
  // };




  const getPolygonPlatformFee = async () => {
    try {
      const [
        polygonStaked1,
        polygonStaked2,
        // polygonStaked3, polygonStaked4,
        polygonStaked5,
        polygonStaked6,
        polygonStaked7,
        polygonStaked8,
        polygonStaked9,
        polygonStaked10,
        polygonStaked11,
        polygonStaked12,
        polygonStaked13,
        polygonStaked14] = await Promise.all([
          polygoncontract1?._methods?.balanceOf(polygonaddress)?.call(),
          polygoncontract2?._methods?.balanceOf(polygonaddress)?.call(),
          // polygoncontract3?._methods?.balanceOf(polygonaddress)?.call(),
          // polygoncontract4?._methods?.balanceOf(polygonaddress)?.call(),
          polygoncontract5?._methods?.balanceOf(polygonaddress)?.call(),
          polygoncontract6?._methods?.balanceOf(polygonaddress)?.call(),
          polygoncontract7?._methods?.balanceOf(polygonaddress)?.call(),
          polygoncontract8?._methods?.balanceOf(polygonaddress)?.call(),
          polygoncontract9?._methods?.balanceOf(polygonaddress)?.call(),
          polygoncontract10?._methods?.balanceOf(polygonaddress)?.call(),
          polygoncontract11?._methods?.balanceOf(polygonaddress)?.call(),
          polygoncontract12?._methods?.balanceOf(polygonaddress)?.call(),
          polygoncontract13?._methods?.balanceOf(polygonaddress)?.call(),
          polygoncontract14?._methods?.balanceOf(polygonaddress)?.call(),
        ]);
      const polygonStaked1Converted = web3.utils.fromWei(polygonStaked1, 'mwei'); // 6 decimals for USDC
      const polygonStaked2Converted = web3.utils.fromWei(polygonStaked2, 'mwei'); // 6 decimals for USDT
      // const polygonStaked3Converted = web3.utils.fromWei(polygonStaked3, 'ether'); // 18 decimals for WETH
      // const polygonStaked4Converted = web3.utils.fromWei(polygonStaked4, 'ether');
      const polygonStaked5Converted = web3.utils.fromWei(polygonStaked5, 'ether'); // 6 decimals for USDC
      const polygonStaked6Converted = web3.utils.fromWei(polygonStaked6, 'ether'); // 6 decimals for USDT
      const polygonStaked7Converted = web3.utils.fromWei(polygonStaked7, 'ether'); // 18 decimals for WETH
      const polygonStaked8Converted = web3.utils.fromWei(polygonStaked8, 'ether');
      const polygonStaked9Converted = web3.utils.fromWei(polygonStaked9, 'ether'); // 6 decimals for USDT
      const polygonStaked10Converted = web3.utils.fromWei(polygonStaked10, 'ether'); // 18 decimals for WETH
      const polygonStaked11Converted = web3.utils.fromWei(polygonStaked11, 'ether');
      const polygonStaked12Converted = web3.utils.fromWei(polygonStaked12, 'ether');
      const polygonStaked13Converted = web3.utils.fromWei(polygonStaked13, 'ether');
      const polygonStaked14Converted = web3.utils.fromWei(polygonStaked14, 'ether');
      const polygonSum =
        (polygonStaked1Converted * usdtvalue) +
        (polygonStaked2Converted * usdcvalue) +
        // (polygonStaked3Converted * wbthvalue) +
        // (polygonStaked4Converted * wethvalue) +
        (polygonStaked5Converted * chainlink) +
        (polygonStaked6Converted * uniswap) +
        (polygonStaked7Converted * rendertoken) +
        (polygonStaked8Converted * shibainu) +
        (polygonStaked9Converted * lidodao) +
        (polygonStaked10Converted * dai) +
        (polygonStaked11Converted * aave) +
        (polygonStaked12Converted * chiliz) +
        (polygonStaked13Converted * mana) +
        (polygonStaked14Converted * maticvalue)
      getSumOfPolygon(polygonSum);
    } catch (error) {
      console.error("Error in getPolygonPlatformFee:", error);
      throw error;
    }
  };
  const getPolygonPlatformFee2 = async () => {
    try {
      const [
        polygonStaked1,
        polygonStaked2,
        // polygonStaked3, polygonStaked4,
        polygonStaked5,
        polygonStaked6,
        polygonStaked7,
        polygonStaked8,
        polygonStaked9,
        polygonStaked10,
        polygonStaked11,
        polygonStaked12,
        polygonStaked13,
        polygonStaked14] = await Promise.all([
          polygoncontract1?._methods?.balanceOf(polygonaddress2)?.call(),
          polygoncontract2?._methods?.balanceOf(polygonaddress2)?.call(),
          // polygoncontract3?._methods?.balanceOf(polygonaddress2)?.call(),
          // polygoncontract4?._methods?.balanceOf(polygonaddress2)?.call(),
          polygoncontract5?._methods?.balanceOf(polygonaddress2)?.call(),
          polygoncontract6?._methods?.balanceOf(polygonaddress2)?.call(),
          polygoncontract7?._methods?.balanceOf(polygonaddress2)?.call(),
          polygoncontract8?._methods?.balanceOf(polygonaddress2)?.call(),
          polygoncontract9?._methods?.balanceOf(polygonaddress2)?.call(),
          polygoncontract10?._methods?.balanceOf(polygonaddress2)?.call(),
          polygoncontract11?._methods?.balanceOf(polygonaddress2)?.call(),
          polygoncontract12?._methods?.balanceOf(polygonaddress2)?.call(),
          polygoncontract13?._methods?.balanceOf(polygonaddress2)?.call(),
          polygoncontract14?._methods?.balanceOf(polygonaddress2)?.call(),
        ]);
      const polygonStaked1Converted = web3.utils.fromWei(polygonStaked1, 'mwei'); // 6 decimals for USDC
      const polygonStaked2Converted = web3.utils.fromWei(polygonStaked2, 'mwei'); // 6 decimals for USDT
      // const polygonStaked3Converted = web3.utils.fromWei(polygonStaked3, 'ether'); // 18 decimals for WETH
      // const polygonStaked4Converted = web3.utils.fromWei(polygonStaked4, 'ether');
      const polygonStaked5Converted = web3.utils.fromWei(polygonStaked5, 'ether'); // 6 decimals for USDC
      const polygonStaked6Converted = web3.utils.fromWei(polygonStaked6, 'ether'); // 6 decimals for USDT
      const polygonStaked7Converted = web3.utils.fromWei(polygonStaked7, 'ether'); // 18 decimals for WETH
      const polygonStaked8Converted = web3.utils.fromWei(polygonStaked8, 'ether');
      const polygonStaked9Converted = web3.utils.fromWei(polygonStaked9, 'ether'); // 6 decimals for USDT
      const polygonStaked10Converted = web3.utils.fromWei(polygonStaked10, 'ether'); // 18 decimals for WETH
      const polygonStaked11Converted = web3.utils.fromWei(polygonStaked11, 'ether');
      const polygonStaked12Converted = web3.utils.fromWei(polygonStaked12, 'ether');
      const polygonStaked13Converted = web3.utils.fromWei(polygonStaked13, 'ether');
      const polygonStaked14Converted = web3.utils.fromWei(polygonStaked14, 'ether');
      const polygonSum =
        (polygonStaked1Converted * usdtvalue) +
        (polygonStaked2Converted * usdcvalue) +
        // (polygonStaked3Converted * wbthvalue) +
        // (polygonStaked4Converted * wethvalue) +
        (polygonStaked5Converted * chainlink) +
        (polygonStaked6Converted * uniswap) +
        (polygonStaked7Converted * rendertoken) +
        (polygonStaked8Converted * shibainu) +
        (polygonStaked9Converted * lidodao) +
        (polygonStaked10Converted * dai) +
        (polygonStaked11Converted * aave) +
        (polygonStaked12Converted * chiliz) +
        (polygonStaked13Converted * mana) +
        (polygonStaked14Converted * maticvalue)
      getSumOfPolygon2(polygonSum);
    } catch (error) {
      console.error("Error in getPolygonPlatformFee:", error);
      throw error;
    }
  };

  const getXlayerPlatformFee = async () => {
    try {
      const [
        xlayerStaked1,
        xlayerStaked2,
        // xlayerStaked3,
        xlayerStaked4,
        // xlayerStaked5,
        // xlayerStaked6,
        // xlayerStaked7,
        xlayerStaked8,
        xlayerStaked9,
      ] = await Promise.all([
        xlayercontract1?._methods?.balanceOf(xlayeraddress)?.call(),
        xlayercontract2?._methods?.balanceOf(xlayeraddress)?.call(),
        // xlayercontract3?._methods?.balanceOf(xlayeraddress2)?.call(),
        xlayercontract4?._methods?.balanceOf(xlayeraddress)?.call(),
        // xlayercontract5?._methods?.balanceOf(xlayeraddress2)?.call(),
        // xlayercontract6?._methods?.balanceOf(xlayeraddress2)?.call(),
        // xlayercontract7?._methods?.balanceOf(xlayeraddress2)?.call(),
        xlayercontract8?._methods?.balanceOf(xlayeraddress)?.call(),
        xlayercontract9?._methods?.balanceOf(xlayeraddress)?.call(),
      ]);
      const xlayerStaked1Converted = web3.utils.fromWei(xlayerStaked1, 'mwei'); // 6 decimals for USDC
      const xlayerStaked2Converted = web3.utils.fromWei(xlayerStaked2, 'mwei'); // 6 decimals for USDT
      // const xlayerStaked3Converted = web3.utils.fromWei(xlayerStaked3, 'gwei'); // 18 decimals for WETH
      const xlayerStaked4Converted = web3.utils.fromWei(xlayerStaked4, 'ether');
      // const xlayerStaked5Converted = web3.utils.fromWei(xlayerStaked5, 'ether'); // 6 decimals for USDC
      // const xlayerStaked6Converted = web3.utils.fromWei(xlayerStaked6, 'ether'); // 6 decimals for USDT
      // const xlayerStaked7Converted = web3.utils.fromWei(xlayerStaked7, 'ether'); // 18 decimals for WETH
      const xlayerStaked8Converted = web3.utils.fromWei(xlayerStaked8, 'ether');
      const xlayerStaked9Converted = web3.utils.fromWei(xlayerStaked9, 'ether');

      const XlayerSum =
        (xlayerStaked1Converted * usdtvalue) +
        (xlayerStaked2Converted * usdcvalue) +
        // (xlayerStaked3Converted * wbthvalue) +
        (xlayerStaked4Converted * wethvalue) +
        // (xlayerStaked5Converted * maticvalue) +
        // (xlayerStaked6Converted * wethvalue) +
        // (xlayerStaked7Converted * shibainu) +
        (xlayerStaked8Converted * dai) +
      (xlayerStaked9Converted * okb);
      

     await getXlayer(XlayerSum);
      


    } catch (error) {
      console.error("Error in getXlayerPlatformFee:", error);
      throw error;
    }
  };
  const getXlayerPlatformFee2 = async () => {
    try {
      const [
        xlayerStaked1,
        xlayerStaked2,
        // xlayerStaked3,
        xlayerStaked4,
        // xlayerStaked5,
        // xlayerStaked6,
        // xlayerStaked7,
        xlayerStaked8,
        xlayerStaked9,
      ] = await Promise.all([
        xlayercontract1?._methods?.balanceOf(xlayeraddress2)?.call(),
        xlayercontract2?._methods?.balanceOf(xlayeraddress2)?.call(),
        // xlayercontract3?._methods?.balanceOf(xlayeraddress2)?.call(),
        xlayercontract4?._methods?.balanceOf(xlayeraddress2)?.call(),
        // xlayercontract5?._methods?.balanceOf(xlayeraddress2)?.call(),
        // xlayercontract6?._methods?.balanceOf(xlayeraddress2)?.call(),
        // xlayercontract7?._methods?.balanceOf(xlayeraddress2)?.call(),
        xlayercontract8?._methods?.balanceOf(xlayeraddress2)?.call(),
        xlayercontract9?._methods?.balanceOf(xlayeraddress2)?.call(),
      ]);
      const xlayerStaked1Converted = web3.utils.fromWei(xlayerStaked1, 'mwei'); // 6 decimals for USDC
      const xlayerStaked2Converted = web3.utils.fromWei(xlayerStaked2, 'mwei'); // 6 decimals for USDT
      // const xlayerStaked3Converted = web3.utils.fromWei(xlayerStaked3, 'gwei'); // 18 decimals for WETH
      const xlayerStaked4Converted = web3.utils.fromWei(xlayerStaked4, 'ether');
      // const xlayerStaked5Converted = web3.utils.fromWei(xlayerStaked5, 'ether'); // 6 decimals for USDC
      // const xlayerStaked6Converted = web3.utils.fromWei(xlayerStaked6, 'ether'); // 6 decimals for USDT
      // const xlayerStaked7Converted = web3.utils.fromWei(xlayerStaked7, 'ether'); // 18 decimals for WETH
      const xlayerStaked8Converted = web3.utils.fromWei(xlayerStaked8, 'ether');
      const xlayerStaked9Converted = web3.utils.fromWei(xlayerStaked9, 'ether');
      const XlayerSum =
        (xlayerStaked1Converted * usdtvalue) +
        (xlayerStaked2Converted * usdcvalue) +
        // (xlayerStaked3Converted * wbthvalue) +
        (xlayerStaked4Converted * wethvalue) +
        // (xlayerStaked5Converted * maticvalue) +
        // (xlayerStaked6Converted * wethvalue) +
        // (xlayerStaked7Converted * shibainu) +
        (xlayerStaked8Converted * dai) +
      (xlayerStaked9Converted * okb)

       await getXlayer2(XlayerSum);
      
    } catch (error) {
      console.error("Error in getXlayerPlatformFee:", error);
      throw error;
    }
  };

  useEffect(() => {
    getXlayerPlatformFee();
    getXlayerPlatformFee2()
  }, [xlayercontract1, xlayeraddress]);
  useEffect(() => {
    getPolygonPlatformFee();
    getPolygonPlatformFee2();
  }, [polygoncontract14, polygoncontract1, polygoncontract2, polygonaddress]);
  useEffect(() => {
    getPlatformFee();
    getPlatformFee2();
  }, [contract1, contract2, contract3, contract4, contract5, address, address2]);

  const displaySum = () => {
    if (version.length === 0) {
      let total = 0;
      if (selectedNetworks.okx) {
        total += xlayer + xlayer2;
      }
      if (selectedNetworks.Ethereum) {
        total += sumofall + sumofall2;
      }
      if (selectedNetworks.Polygon) {
        total += sumOfPolygon + sumOfPolygon2;
      }
      return total;
    }
    if (version.length === 1) {
      let total = 0;
      if (version.includes('version 1')) {
        if (selectedNetworks.Ethereum) total += sumofall;
        if (selectedNetworks.Polygon) total += sumOfPolygon;
        if (selectedNetworks.okx) total += xlayer;
      }
      if (version.includes('version 2')) {
        if (selectedNetworks.Ethereum) total += sumofall2;
        if (selectedNetworks.Polygon) total += sumOfPolygon2;
        if (selectedNetworks.okx) total += xlayer2;
      }
      return total;
    }
    if (version.length > 1) {
      let total = 0;
      if (selectedNetworks.Ethereum) {
        total += sumofall + sumofall2;
      }
      if (selectedNetworks.Polygon) {
        total += sumOfPolygon + sumOfPolygon2;
      }
      if (selectedNetworks.okx) {
        total += xlayer + xlayer2;
      }
      return total;
    }
    let total = 0;
    if (selectedNetworks.Ethereum) {
      total += sumofall + sumofall2;
    }
    if (selectedNetworks.Polygon) {
      total += sumOfPolygon + sumOfPolygon2;
    }
    if (selectedNetworks.okx) {
      total += xlayer + xlayer2;
    }
    return total;
  };


  const getUsersData = async (times) => {
    if (times && times?.[0]?.startDate && times?.[0]?.endDate) {
      const startDate = moment(times[0].startDate).utc().startOf('day').format("YYYY-MM-DDTHH:mm:ss[Z]");
      const endDate = moment(times[0].endDate).utc().endOf('day').format("YYYY-MM-DDTHH:mm:ss[Z]");
      const dynamicQuery = `?startDate=${startDate}&endDate=${endDate}`;
      try {
        const response = await axios.get(`${api_url}/admins/users-socials${dynamicQuery}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUsersData(response?.data?.data);
      } catch (err) {
        console.error('Error fetching influencerssss:', err);
      }
    }
  };
  useEffect(() => {
    const today = new Date();
    const specificDateRange = [
      {
        startDate: today, // Today
        endDate: today, // Today
      },
    ];
    getUsersData(specificDateRange, state);
  }, []);

  const getUsersEncrypts = async () => {
    try {
      const response = await axios.get(`${api_url}/admins/users-encrypts`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUsersEncrypts(response?.data?.data);
    } catch (err) {
      console.error('Error fetching influencers:', err);
    }
  };
  useEffect(() => {
    getUsersEncrypts();
  }, []);
  const getUsersSends = async () => {

    try {
      const response = await axios.get(`${api_url}/admins/users-sends`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUsersSends(response?.data?.data);
    } catch (err) {
      console.error('Error fetching influencers:', err);
    }
  };
  useEffect(() => {
    getUsersSends();
  }, []);
  const getUsersPoints = async (times) => {
    if (times && times?.[0]?.startDate && times?.[0]?.endDate) {
      const startDate = moment(times[0].startDate).utc().startOf('day').format("YYYY-MM-DDTHH:mm:ss[Z]");
      const endDate = moment(times[0].endDate).utc().endOf('day').format("YYYY-MM-DDTHH:mm:ss[Z]");
      const dynamicQuery = `?startDate=${startDate}&endDate=${endDate}`;
      try {
        const response = await axios.get(`${api_url}/admins/users-countries${dynamicQuery}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setCountries(response?.data?.data);
      } catch (err) {
        console.error('Error fetching influencers:', err);
      }
    }
  };
  useEffect(() => {
    const today = new Date();
    const specificDateRange = [
      {
        startDate: today, // Today
        endDate: today, // Today
      },
    ];
    getUsersPoints(specificDateRange, state);
  }, []);

  const getCountries = async () => {
    try {
      const response = await axios.get(`${api_url}/admins/users-points`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUsersPoints(response?.data?.data);
    } catch (err) {
      console.error('Error fetching influencers:', err);
    }
  };
  useEffect(() => {
    getCountries();
  }, []);


  const getSelectedNetworksArray = () => {
    return Object.entries(selectedNetworks)
      .filter(([_, isSelected]) => isSelected)
      .map(([network]) => {
        if (network === 'XLayer') return 'okx';
        return network.toLowerCase();
      });
  };
  const getLatestTransactions = async () => {
    setLoaderTransaction(true);
    const { startDate, endDate } = filterDates;
    const formattedStartDate = moment(startDate).utc().startOf('day').format("YYYY-MM-DDTHH:mm:ss[Z]");
    const formattedEndDate = moment(endDate).utc().endOf('day').format("YYYY-MM-DDTHH:mm:ss[Z]");
    const baseApiUrl = `${api_url}/admins/transactions`;

    // Handle version query for multiple versions
    const versionQuery = version.length === 1 ? `&version=${version[0]}` : '';
    const chains = getSelectedNetworksArray();
    const chainQuery = chains.length ? `&chain=${chains.join(',')}` : '';
    const dynamicQuery = `?startDate=${formattedStartDate}&endDate=${formattedEndDate}&offset=${page}&limit=10${versionQuery}${chainQuery}`;

    try {
      const response = await axios.get(`${baseApiUrl}${dynamicQuery}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTransactions(response?.data?.data?.transactions);
      setPageCount(response?.data?.data?.count);
      setLoaderTransaction(false);
    } catch (err) {
      console.error('Error fetching transactions:', err);
      if (err.response && err.response.status === 401) {
        navigate('/');
      }
      setLoaderTransaction(false);
    }
  };

  useEffect(() => {
    getLatestTransactions();
  }, [page, filterDates, version, selectedNetworks]);


  // const getLatestTransactions = async () => {
  //   setLoaderTransaction(true);
  //   const { startDate, endDate } = filterDates;
  //   const formattedStartDate = moment(startDate).utc().startOf('day').format("YYYY-MM-DDTHH:mm:ss[Z]");
  //   const formattedEndDate = moment(endDate).utc().endOf('day').format("YYYY-MM-DDTHH:mm:ss[Z]");
  //   const baseApiUrl = `${api_url}/admins/transactions`;
  //   const versionQuery = version ? `&version=${version}` : '';
  //   const chains = getSelectedNetworksArray();
  //   const chainQuery = chains.length ? `&chain=${chains.join(',')}` : '';
  //   const dynamicQuery = `?startDate=${formattedStartDate}&endDate=${formattedEndDate}&offset=${page}&limit=10${versionQuery}${chainQuery}`;

  //   try {
  //     const response = await axios.get(`${baseApiUrl}${dynamicQuery}`, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });
  //     setTransactions(response?.data?.data?.transactions);
  //     setPageCount(response?.data?.data?.count);
  //     setLoaderTransaction(false);
  //   } catch (err) {
  //     console.error('Error fetching transactions:', err);
  //     if (err.response && err.response.status === 401) {
  //       navigate('/');
  //     }
  //     setLoaderTransaction(false);
  //   }
  // };

  // useEffect(() => {
  //   getLatestTransactions();
  // }, [page, filterDates, version, selectedNetworks]);


  const startIndex = page * itemsPerPage + 1;
  const endIndex = Math.min((page + 1) * itemsPerPage, pageCount);
  const handlePageChange = (e) => {
    const selectedPage = e.selected;
    setPage(selectedPage);
  };

  const getEncryptDecrypt = async (times) => {
    if (times && times?.[0]?.startDate && times?.[0]?.endDate) {
      const startDate = moment(times[0].startDate).utc().startOf('day').format("YYYY-MM-DDTHH:mm:ss[Z]");
      const endDate = moment(times[0].endDate).utc().endOf('day').format("YYYY-MM-DDTHH:mm:ss[Z]");

      // Handle version query for multiple versions
      const versionQuery = version.length === 1 ? `&version=${version[0]}` : '';
      // const selectedNetwork = Object.keys(selectedNetworks).find(
      //   (network) => selectedNetworks[network]
      // );
      const chains = getSelectedNetworksArray();
      const chainQuery = chains.length ? `&chain=${chains.join(',')}` : '';
      const dynamicQuery = `?startDate=${startDate}&endDate=${endDate}${versionQuery}${chainQuery}`;

      try {
        const response = await axios.get(`${api_url}/admins/transactions/get-amounts-data${dynamicQuery}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setDecryptData(response?.data?.data?.decryptData?.[0]);
        setEncryptData(response?.data?.data?.encryptData?.[0]);
      } catch (err) {
        console.error('Error fetching encryption/decryption data:', err);
      }
    }
  };

  useEffect(() => {
    const today = new Date();
    const specificDateRange = [
      {
        startDate: today,
        endDate: today,
      },
    ];
    getEncryptDecrypt(specificDateRange);
  }, [version, selectedNetworks]);

  // const getEncryptDecrypt = async (times) => {
  //   if (times && times?.[0]?.startDate && times?.[0]?.endDate) {
  //     const startDate = moment(times[0].startDate).utc().startOf('day').format("YYYY-MM-DDTHH:mm:ss[Z]");
  //     const endDate = moment(times[0].endDate).utc().endOf('day').format("YYYY-MM-DDTHH:mm:ss[Z]");
  //     const versionQuery = version ? `&version=${version}` : '';
  //     const selectedNetwork = Object.keys(selectedNetworks).find(
  //       (network) => selectedNetworks[network]
  //     );
  //     const chainQuery = selectedNetwork ? `&chain=${selectedNetwork.toLowerCase()}` : '';
  //     const dynamicQuery = `?startDate=${startDate}&endDate=${endDate}${versionQuery}${chainQuery}`;

  //     try {
  //       const response = await axios.get(`${api_url}/admins/transactions/get-amounts-data${dynamicQuery}`, {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       });
  //       setDecryptData(response?.data?.data?.decryptData?.[0]);
  //       setEncryptData(response?.data?.data?.encryptData?.[0]);
  //     } catch (err) {
  //       console.error('Error fetching encryption/decryption data:', err);
  //     }
  //   }
  // };
  // useEffect(() => {
  //   const today = new Date();
  //   const specificDateRange = [
  //     {
  //       startDate: today,
  //       endDate: today,
  //     },
  //   ];
  //   getEncryptDecrypt(specificDateRange);
  // }, [version, selectedNetworks]);


  const getTvlGraph = async (times) => {
    setTvlGraph([]);
    setLoaderTvl(true);
    if (times && times?.[0]?.startDate && times?.[0]?.endDate) {
      const startDate = moment(times[0].startDate).utc().startOf('day').format("YYYY-MM-DDTHH:mm:ss[Z]");
      const endDate = moment(times[0].endDate).utc().endOf('day').format("YYYY-MM-DDTHH:mm:ss[Z]");

      // Handle version query for multiple versions
      const versionQuery = version.length === 1 ? `&version=${version[0]}` : ''; // Don't send version if multiple versions are selected
      const chains = getSelectedNetworksArray();
      const chainQuery = chains.length ? `&chain=${chains.join(',')}` : '';
      const dynamicQuery = `?startDate=${startDate}&endDate=${endDate}${versionQuery}${chainQuery}`;

      try {
        const response = await axios.get(`${api_url}/admins/transactions/get-tvl-graph-data${dynamicQuery}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setTvlGraph(response?.data?.data);
        setLoaderTvl(false);
      } catch (err) {
        console.error('Error fetching TVL graph data:', err);
        setLoaderTvl(false);
      }
    }
  };



  // const getTvlGraph = async (times) => {
  //   setTvlGraph([]);
  //   setLoaderTvl(true);
  //   if (times && times?.[0]?.startDate && times?.[0]?.endDate) {
  //     const startDate = moment(times[0].startDate).utc().startOf('day').format("YYYY-MM-DDTHH:mm:ss[Z]");
  //     const endDate = moment(times[0].endDate).utc().endOf('day').format("YYYY-MM-DDTHH:mm:ss[Z]");
  //     const versionQuery = version ? `&version=${version}` : '';
  //     const selectedNetwork = Object.keys(selectedNetworks).find(
  //       (network) => selectedNetworks[network]
  //     );
  //     const chainQuery = selectedNetwork ? `&chain=${selectedNetwork.toLowerCase()}` : '';
  //     const dynamicQuery = `?startDate=${startDate}&endDate=${endDate}${versionQuery}${chainQuery}`;

  //     try {
  //       const response = await axios.get(`${api_url}/admins/transactions/get-tvl-graph-data${dynamicQuery}`, {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       });
  //       setTvlGraph(response?.data?.data);
  //       setLoaderTvl(false);
  //     } catch (err) {
  //       console.error('Error fetching TVL graph data:', err);
  //       setLoaderTvl(false);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   const today = new Date();
  //   const specificDateRange = [
  //     {
  //       startDate: today,
  //       endDate: today,
  //     },
  //   ];
  //   getTvlGraph(specificDateRange);
  // }, [version, selectedNetworks]);



  const getCountriesGraph = async (times) => {
    setLoader(true);
    if (times && times?.[0]?.startDate && times?.[0]?.endDate) {
      const startDate = moment(times[0].startDate).utc().startOf('day').format("YYYY-MM-DDTHH:mm:ss[Z]");
      const endDate = moment(times[0].endDate).utc().endOf('day').format("YYYY-MM-DDTHH:mm:ss[Z]");

      // Handle version query for multiple versions
      const versionQuery = version.length === 1 ? `&version=${version[0]}` : ''; // Don't send version if multiple versions are selected
      const chains = getSelectedNetworksArray();
      const chainQuery = chains.length ? `&chain=${chains.join(',')}` : '';
      const dynamicQuery = `?startDate=${startDate}&endDate=${endDate}${versionQuery}${chainQuery}`;

      try {
        const response = await axios.get(`${api_url}/admins/transactions/get-country-graph-data${dynamicQuery}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setCountriesGraph(response?.data?.data);
        setLoader(false);
      } catch (err) {
        console.error('Error fetching country graph data:', err);
        setLoader(false);
      }
    }
  };

  useEffect(() => {
    const today = new Date();
    const specificDateRange = [
      {
        startDate: today,
        endDate: today,
      },
    ];
    getTvlGraph(specificDateRange);
    getCountriesGraph(specificDateRange); // Call both APIs when version changes
  }, [version, selectedNetworks]);




  // const getCountriesGraph = async (times) => {
  //   setLoader(true);
  //   if (times && times?.[0]?.startDate && times?.[0]?.endDate) {
  //     const startDate = moment(times[0].startDate).utc().startOf('day').format("YYYY-MM-DDTHH:mm:ss[Z]");
  //     const endDate = moment(times[0].endDate).utc().endOf('day').format("YYYY-MM-DDTHH:mm:ss[Z]");
  //     const versionQuery = version ? `&version=${version}` : '';
  //     const dynamicQuery = `?startDate=${startDate}&endDate=${endDate}${versionQuery}`;

  //     try {
  //       const response = await axios.get(`${api_url}/admins/transactions/get-country-graph-data${dynamicQuery}`, {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       });
  //       setCountriesGraph(response?.data?.data);
  //       setLoader(false);
  //     } catch (err) {
  //       console.error('Error fetching country graph data:', err);
  //       setLoader(false);
  //     }
  //   }
  // };
  // useEffect(() => {
  //   const today = new Date();
  //   const specificDateRange = [
  //     {
  //       startDate: today,
  //       endDate: today,
  //     },
  //   ];
  //   getCountriesGraph(specificDateRange, state);
  // }, [version]); 


  const formatAmount = (amount) => {
    return Number.isNaN(Number(amount)) ? '0' : Number(amount?.toFixed(4)).toLocaleString();
  };


  let sumofbadges = (usersdata?.discordFollowers + usersdata?.emailVerified + usersdata?.kycVerified + usersdata?.telegramFollowers + usersdata?.twitterFollowers +
    usersencrypt?.encrypt_10000 + usersencrypt?.encrypt_20000 + usersencrypt?.encrypt_50000 + usersencrypt?.encrypt_100000 + usersencrypt?.encrypt_500000 + usersencrypt?.encrypt_1000000 +
    userssends?.send_50 + userssends?.send_100 +
    userssends?.send_200 + userssends?.send_500)

  const handleCalendar = (startDate, endDate) => {
    getAdminData([{ startDate, endDate }]);
    getUsersData([{ startDate, endDate }]);
    getUsersPoints([{ startDate, endDate }]);
    getEncryptDecrypt([{ startDate, endDate }]);
    getLatestTransactions([{ startDate, endDate }]);
    getTvlGraph([{ startDate, endDate }]);
    getCountriesGraph([{ startDate, endDate }]);
    setFilterDates({ startDate, endDate });
    setPage(0);
  }


  const toggleVersion = (versionOption) => {
    setVersion((prevVersion) => {
      if (versionOption === '') {
        return [];  // Clear selection
      } else if (prevVersion.includes(versionOption)) {
        return prevVersion.filter((v) => v !== versionOption);  // Remove version if already selected
      } else {
        return [...prevVersion, versionOption];  // Add version if not selected
      }
    });
  };


  return (
    <>
      <Header />
      <section className="main-banner">
        <div className="custom-container">
          <div className="twice-items">
            <h6>Welcome, admin</h6>
            <div className="twice-btns">
              {/* <div className="dropdown">
                <button
                  className="dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="outside"
                  aria-expanded="false"
                >
                  {version === '' ? "All" : version || "All"}
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                    <path d="M12 8L9 11L6 8" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </button>
                <ul className="dropdown-menu">
                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={() => {
                        setVersion('version 1');
                        getPlatformFee();
                      }}
                    >
                      <div className="mycheckbox">
                        <label className="innercheckbox" htmlFor="firstinput">
                          <input
                            type="checkbox"
                            name="color"
                            id="firstinput"
                            checked={version === 'version 1'}
                            readOnly
                          />
                          <span className="innercheckmark"></span>
                        </label>
                        <p className="innercheckboxpara">V1 Data</p>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={() => {
                        setVersion('version 2');
                        getPlatformFee2();
                      }}
                    >
                      <div className="mycheckbox">
                        <label className="innercheckbox" htmlFor="secinput">
                          <input
                            type="checkbox"
                            name="color"
                            id="secinput"
                            checked={version === 'version 2'}
                            readOnly
                          />
                          <span className="innercheckmark"></span>
                        </label>
                        <p className="innercheckboxpara">V2 Data</p>
                      </div>
                    </a>
                  </li>
                </ul>
              </div> */}
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic">
                  {version.length === 0 ? toggleVersion('version 2') : version.join(', ') || "All"}
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                    <path d="M12 8L9 11L6 8" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {/* <Dropdown.Item onClick={() => { toggleVersion(''); getTvlGraph(); getCountriesGraph(); }}>
                    <div className="mycheckbox">
                      <label className="innercheckbox" htmlFor="allinput">
                        <input
                          type="checkbox"
                          name="color"
                          id="allinput"
                          checked={version.length === 0}
                          readOnly
                        />
                        <span className="innercheckmark"></span>
                      </label>
                      All
                    </div>
                  </Dropdown.Item> */}
                  <Dropdown.Item onClick={() => {
                    toggleVersion('version 1');
                    getTvlGraph();
                    getCountriesGraph();
                    getPlatformFee();
                  }}>
                    <div className="mycheckbox">
                      <label className="innercheckbox" htmlFor="version1input">
                        <input
                          type="checkbox"
                          name="color"
                          id="version1input"
                          checked={version.includes('version 1')}
                          readOnly
                        />
                        <span className="innercheckmark"></span>
                      </label>
                      Version 1
                    </div>
                  </Dropdown.Item>

                  <Dropdown.Item onClick={() => {
                    toggleVersion('version 2');
                    getTvlGraph();
                    getCountriesGraph();
                    getPlatformFee2();
                  }}>
                    <div className="mycheckbox">
                      <label className="innercheckbox" htmlFor="version2input">
                        <input
                          type="checkbox"
                          name="color"
                          id="version2input"
                          checked={version.includes('version 2')}
                          readOnly
                        />
                        <span className="innercheckmark"></span>
                      </label>
                      Version 2
                    </div>
                  </Dropdown.Item>

                </Dropdown.Menu>
              </Dropdown>
              {/* 
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic">
                  {version === '' ? "All" : version || "All"}
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                    <path d="M12 8L9 11L6 8" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => { setVersion(''); getPlatformFee(); }}>
                    <div className="mycheckbox">
                      <label className="innercheckbox" htmlFor="allinput">
                        <input
                          type="checkbox"
                          name="color"
                          id="allinput"
                          checked={version === ''}
                          readOnly
                        />
                        <span className="innercheckmark"></span>
                      </label>
                      All
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => { setVersion('version 1'); getPlatformFee(); }}>
                    <div className="mycheckbox">
                      <label className="innercheckbox" htmlFor="version1input">
                        <input
                          type="checkbox"
                          name="color"
                          id="version1input"
                          checked={version === 'version 1'}
                          readOnly
                        />
                        <span className="innercheckmark"></span>
                      </label>
                      Version 1
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => { setVersion('version 2'); getPlatformFee2(); }}>
                    <div className="mycheckbox">
                      <label className="innercheckbox" htmlFor="version2input">
                        <input
                          type="checkbox"
                          name="color"
                          id="version2input"
                          checked={version === 'version 2'}
                          readOnly
                        />
                        <span className="innercheckmark"></span>
                      </label>
                      Version 2
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>  */}

              <div className="custom-tab-bar">
                <a
                  onClick={() => {
                    // const startDate = moment().startOf('day').utc().format("YYYY-MM-DDTHH:mm:ss");
                    // const endDate = moment().endOf('day').utc().format("YYYY-MM-DDTHH:mm:ss");
                    const startDate = moment().utc().startOf('day').format("YYYY-MM-DDTHH:mm:ss[Z]");
                    const endDate = moment().utc().endOf('day').format("YYYY-MM-DDTHH:mm:ss[Z]");
                    handleCalendar(startDate, endDate);
                    setalllset("1");
                    setDataTime("1");
                  }}
                  className={alllset == "1" ? "active" : "noactive"}
                >
                  1D
                </a>
                <a
                  onClick={() => {
                    const startDate = moment().startOf('day').subtract(6, 'days').utc().format("YYYY-MM-DDTHH:mm:ss");
                    const endDate = moment().endOf('day').subtract(1, 'days').utc().format("YYYY-MM-DDTHH:mm:ss");
                    handleCalendar(startDate, endDate);
                    setalllset("seven");
                    setDataTime("seven");
                  }}
                  className={alllset == "seven" ? "active" : "noactive"}
                >
                  7D
                </a>
                <a

                  onClick={() => {
                    const startDate = moment().startOf('day').subtract(30, 'days').utc().format("YYYY-MM-DDTHH:mm:ss");
                    const endDate = moment().endOf('day').utc().format("YYYY-MM-DDTHH:mm:ss");
                    handleCalendar(startDate, endDate);
                    setalllset("month");
                    setDataTime("month");

                  }}
                  className={alllset == "month" ? "active" : "noactive"}
                >
                  1M
                </a>
                <a
                  onClick={() => {
                    const startDate = moment().startOf('day').subtract(365, 'days').utc().format("YYYY-MM-DDTHH:mm:ss");
                    const endDate = moment().endOf('day').utc().format("YYYY-MM-DDTHH:mm:ss");
                    handleCalendar(startDate, endDate);
                    setalllset("year");
                    setDataTime("year");
                  }}
                  className={alllset == "year" ? "active" : "noactive"}
                >
                  1Y
                </a>
                <a
                  onClick={() => {
                    const startDate = moment().startOf('day').subtract(365, 'days').utc().format("YYYY-MM-DDTHH:mm:ss");
                    const endDate = moment().endOf('day').utc().format("YYYY-MM-DDTHH:mm:ss");
                    setalllset("all");
                    setDataTime("");
                    handleCalendar(startDate, endDate)
                  }}
                  className={alllset == "all" ? "active" : "noactive"}
                >
                  All
                </a>
                <a onClick={() => setShowCalendar(true)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                    <path d="M8.6665 4H7.8667C6.74659 4 6.18612 4 5.7583 4.21799C5.38198 4.40973 5.07624 4.71547 4.88449 5.0918C4.6665 5.51962 4.6665 6.08009 4.6665 7.2002V8M8.6665 4H16.6665M8.6665 4V2M16.6665 4H17.4667C18.5868 4 19.1461 4 19.5739 4.21799C19.9502 4.40973 20.257 4.71547 20.4487 5.0918C20.6665 5.5192 20.6665 6.07899 20.6665 7.19691V8M16.6665 4V2M4.6665 8V16.8002C4.6665 17.9203 4.6665 18.4801 4.88449 18.9079C5.07624 19.2842 5.38198 19.5905 5.7583 19.7822C6.18571 20 6.7455 20 7.86341 20H17.4696C18.5875 20 19.1465 20 19.5739 19.7822C19.9502 19.5905 20.257 19.2842 20.4487 18.9079C20.6665 18.4805 20.6665 17.9215 20.6665 16.8036V8M4.6665 8H20.6665M16.6665 16H16.6685L16.6685 16.002L16.6665 16.002V16ZM12.6665 16H12.6685L12.6685 16.002L12.6665 16.002V16ZM8.6665 16H8.6685L8.66846 16.002L8.6665 16.002V16ZM16.6685 12V12.002L16.6665 12.002V12H16.6685ZM12.6665 12H12.6685L12.6685 12.002L12.6665 12.002V12ZM8.6665 12H8.6685L8.66846 12.002L8.6665 12.002V12Z" stroke="#A2A4A6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  {showcalendar && (
                    <div className="cal set-custom-calendar-div">
                      <DateRange
                        editableDateInputs={true}
                        moveRangeOnFirstSelection={false}
                        ranges={[state]}
                        onChange={(item) => {
                          const startDate = moment(item?.selection?.startDate).subtract(-2, 'day').utc().format("Y-MM-DDT00:00:00");
                          const endDate = moment(item?.selection?.endDate).subtract(-1, 'day').utc().format("Y-MM-DDT23:59:59");
                          setState(item.selection);
                          handleCalendar(startDate, endDate);
                          if (item.selection.startDate && item.selection.endDate) {
                            setShowCalendar(false);
                          }
                          setalllset("")
                        }}
                      />
                    </div>
                  )}
                </a>
              </div>
            </div>
          </div>
          <div className="bottom-content">
            <div className="mainnet-part">
              <div className="heading">
                <h6>MAINNET STATS</h6>
              </div>
              <div className="bottom-boxes">
                <div className="single-item">
                  <img src="\assets\totalvaluelocked.svg" alt="img" className='img-fluid' />
                  <div className="text-item">
                    <p>Total Value Locked</p>
                    <h6>
                      {Number(displaySum())?.toLocaleString() ?? 0}
                    </h6>
                  </div>

                </div>
                <div className="single-item">
                  <img src="\assets\wallet.svg" alt="img" className='img-fluid' />
                  <div className="text-item">
                    <p>Wallets Created</p>
                    <h6>
                      {admindata?.walletCount?.data?.[0]?.count
                        ? Number(admindata?.walletCount?.data[0]?.count?.toFixed(0))?.toLocaleString()
                        : '0'}
                      {/* <span className='red-text'>+5.4%</span> */}
                    </h6>

                  </div>
                </div>
                {/* <div className="single-item">
                  <img src="\assets\appvisitor.svg" alt="img" className='img-fluid' />
                  <div className="text-item">
                    <p>App Visitors</p>
                    <h6>
                      0
                     
                    </h6>

                  </div>
                </div> */}
                {/* <span className='green-text'>+2.3%</span> */}
                {/* <div className="single-item">
                  <img src="\assets\totaltransactions.svg" alt="img" className='img-fluid' />
                  <div className="text-item">
                    <p>Total Transactions</p>
                    <h6>
                      {admindata?.totalTransactions?.data?.[0]?.count
                        ? Number(admindata.totalTransactions.data[0].count.toFixed(0)).toLocaleString()
                        : '0'}
                    </h6>

                  </div>
                </div>
                <div className="single-item">
                  <img src="\assets\totalpoints.svg" alt="img" className='img-fluid' />
                  <div className="text-item">
                    <p>Total Points Earned</p>
                    <h6>
                      {admindata?.totalPoints?.data?.[0]?.count
                        ? Number(admindata.totalPoints.data[0].count.toFixed(2)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                        : '0'}
                    </h6>

                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div className="bottom-twice-graph">
            <div className="graph-parent">
              <h6 className="main-heading">
                total value locked
              </h6>
              <ValueLockedGraph tvlgraph={tvlgraph} loader={loadertvl} />
            </div>
            <div className="graph-parent">
              <h6 className="main-heading">
                top 10 countries by value locked
              </h6>
              <Countrychart countriesgraph={countriesgraph} loader={loader} />
            </div>
          </div>
          <div className="bottom-twice-box">
            <div className="single-box">
              <div className="main-head">
                <h6>Encryptions</h6>
              </div>
              <div className="inside-box">
                <div className="upper-text">
                  <div className="inner-text">
                    <p>Amount Encrypted</p>
                    <h6> ${Number.isNaN(Number(encryptData?.overallTotal))
                      ? "0"
                      : Number(encryptData?.overallTotal?.toFixed(2))?.toLocaleString()}
                      {/* <span className='red-text'>-1.3%</span> */}
                    </h6>
                  </div>
                  <div class="dropdown dropdown-sepcificmenu-hiehgt">
                    <button class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                        <path d="M16 10.1437L12 14.1437L8 10.1437" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </button>
                    <ul class="dropdown-menu">
                      {/* <div className="inside-coins">
                        <div className="single-coin">
                          <div className="left-text">
                            <img src="\assets\coins\eth.svg" alt="img" className='img-fluid' />
                            <p>ETH</p>
                          </div>
                          <h6>0 ETH</h6>
                        </div>
                        <div className="single-coin">
                          <div className="left-text">
                            <img src="\assets\coins\weth.svg" alt="img" className='img-fluid' />
                            <p>WETH</p>
                          </div>
                          <h6>0 WETH</h6>
                        </div>
                        <div className="single-coin">
                          <div className="left-text">
                            <img src="\assets\coins\btc.svg" alt="img" className='img-fluid' />
                            <p>WBTC</p>
                          </div>
                          <h6>0 WBTC</h6>
                        </div>
                        <div className="single-coin">
                          <div className="left-text">
                            <img src="\assets\coins\usdt.svg" alt="img" className='img-fluid' />
                            <p>USDT</p>
                          </div>
                          <h6>0 USDT</h6>
                        </div>
                        <div className="single-coin">
                          <div className="left-text">
                            <img src="\assets\coins\usdc.svg" alt="img" className='img-fluid' />
                            <p>USDC</p>
                          </div>
                          <h6>0 USDC</h6>
                        </div>
                      </div> */}
                      <div className="inside-coins">
                        <div className="single-coin">
                          <div className="left-text">
                            <img src="/assets/coins/weth.svg" alt="img" className='img-fluid' />
                            <p>WETH</p>
                          </div>
                          <h6>{formatAmount(encryptData?.assets?.find(asset => asset.asset === 'ethereum')?.totalAmount)} WETH</h6>
                        </div>
                        <div className="single-coin">
                          <div className="left-text">
                            <img src="/assets/coins/btc.svg" alt="img" className='img-fluid' />
                            <p>WBTC</p>
                          </div>
                          <h6>{formatAmount(encryptData?.assets?.find(asset => asset.asset === 'wrapped-bitcoin')?.totalAmount)} WBTC</h6>
                        </div>
                        <div className="single-coin">
                          <div className="left-text">
                            <img src="/assets/coins/usdt.svg" alt="img" className='img-fluid' />
                            <p>USDT</p>
                          </div>
                          <h6>{formatAmount(encryptData?.assets?.find(asset => asset.asset === 'tether')?.totalAmount)} USDT</h6>
                        </div>
                        <div className="single-coin">
                          <div className="left-text">
                            <img src="/assets/coins/usdc.svg" alt="img" className='img-fluid' />
                            <p>USDC</p>
                          </div>
                          <h6>{formatAmount(encryptData?.assets?.find(asset => asset.asset === 'usd-coin')?.totalAmount)} USDC</h6>
                        </div>
                        <div className="single-coin">
                          <div className="left-text">
                            <img src="/assets/coins/dop.svg" alt="img" className='img-fluid' />
                            <p>DOP</p>
                          </div>
                          <h6>{formatAmount(encryptData?.assets?.find(asset => asset.asset === 'data-ownership-protocol')?.totalAmount)} DOP</h6>
                        </div>
                        <div className="single-coin">
                          <div className="left-text">
                            <img src="/assets/coins/matic.svg" alt="img" className='img-fluid' />
                            <p>MATIC</p>
                          </div>
                          <h6>{formatAmount(encryptData?.assets?.find(asset => asset.asset === 'matic-network')?.totalAmount)} MATIC</h6>
                        </div>
                        <div className="single-coin">
                          <div className="left-text">
                            <img src="/assets/coins/matic.svg" alt="img" className='img-fluid' />
                            <p>POL</p>
                          </div>
                          <h6>{formatAmount(encryptData?.assets?.find(asset => asset.asset === 'polygon-ecosystem-token')?.totalAmount)} POL</h6>
                        </div>
                        <div className="single-coin">
                          <div className="left-text">
                            <img src="/assets/coins/pepe.svg" alt="img" className='img-fluid' />
                            <p>PEPE</p>
                          </div>
                          <h6>{formatAmount(encryptData?.assets?.find(asset => asset.asset === 'pepe')?.totalAmount)} PEPE</h6>
                        </div>
                        <div className="single-coin">
                          <div className="left-text">
                            <img src="/assets/coins/link.svg" alt="img" className='img-fluid' />
                            <p>LINK</p>
                          </div>
                          <h6>{formatAmount(encryptData?.assets?.find(asset => asset.asset === 'chainlink')?.totalAmount)} LINK</h6>
                        </div>
                        <div className="single-coin">
                          <div className="left-text">
                            <img src="/assets/coins/uni.svg" alt="img" className='img-fluid' />
                            <p>UNI</p>
                          </div>
                          <h6>{formatAmount(encryptData?.assets?.find(asset => asset.asset === 'uniswap')?.totalAmount)} UNI</h6>
                        </div>
                        <div className="single-coin">
                          <div className="left-text">
                            <img src="/assets/coins/rndr.svg" alt="img" className='img-fluid' />
                            <p>RNDR</p>
                          </div>
                          <h6>{formatAmount(encryptData?.assets?.find(asset => asset.asset === 'render-token')?.totalAmount)} RNDR</h6>
                        </div>
                        <div className="single-coin">
                          <div className="left-text">
                            <img src="/assets/coins/shib.svg" alt="img" className='img-fluid' />
                            <p>SHIB</p>
                          </div>
                          <h6>{formatAmount(encryptData?.assets?.find(asset => asset.asset === 'shiba-inu')?.totalAmount)} SHIB</h6>
                        </div>
                        <div className="single-coin">
                          <div className="left-text">
                            <img src="/assets/coins/ldo.svg" alt="img" className='img-fluid' />
                            <p>LDO</p>
                          </div>
                          <h6>{formatAmount(encryptData?.assets?.find(asset => asset.asset === 'lido-dao')?.totalAmount)} LDO</h6>
                        </div>
                        <div className="single-coin">
                          <div className="left-text">
                            <img src="/assets/coins/floki.svg" alt="img" className='img-fluid' />
                            <p>FLOKI</p>
                          </div>
                          <h6>{formatAmount(encryptData?.assets?.find(asset => asset.asset === 'floki')?.totalAmount)} FLOKI</h6>
                        </div>
                        <div className="single-coin">
                          <div className="left-text">
                            <img src="/assets/coins/dai.svg" alt="img" className='img-fluid' />
                            <p>DAI</p>
                          </div>
                          <h6>{formatAmount(encryptData?.assets?.find(asset => asset.asset === 'dai')?.totalAmount)} DAI</h6>
                        </div>
                        <div className="single-coin">
                          <div className="left-text">
                            <img src="/assets/coins/aave.svg" alt="img" className='img-fluid' />
                            <p>AAVE</p>
                          </div>
                          <h6>{formatAmount(encryptData?.assets?.find(asset => asset.asset === 'aave')?.totalAmount)} AAVE</h6>
                        </div>
                        <div className="single-coin">
                          <div className="left-text">
                            <img src="/assets/coins/ens.svg" alt="img" className='img-fluid' />
                            <p>ENS</p>
                          </div>
                          <h6>{formatAmount(encryptData?.assets?.find(asset => asset.asset === 'ethereum-name-service')?.totalAmount)} ENS</h6>
                        </div>
                        <div className="single-coin">
                          <div className="left-text">
                            <img src="/assets/coins/chz.svg" alt="img" className='img-fluid' />
                            <p>CHZ</p>
                          </div>
                          <h6>{formatAmount(encryptData?.assets?.find(asset => asset.asset === 'chiliz')?.totalAmount)} CHZ</h6>
                        </div>
                        <div className="single-coin">
                          <div className="left-text">
                            <img src="/assets/coins/mana.svg" alt="img" className='img-fluid' />
                            <p>MANA</p>
                          </div>
                          <h6>{formatAmount(encryptData?.assets?.find(asset => asset.asset === 'decentraland')?.totalAmount)} MANA</h6>
                        </div>
                      </div>
                    </ul>
                  </div>
                </div>
                <div className="bottom-text">
                  <p>No. of Encrypts</p>
                  <h6>
                    {/* {encryptData?.totalCount} */}
                    {Number.isNaN(Number(encryptData?.totalCount))
                      ? "0"
                      : Number(encryptData?.totalCount?.toFixed(4))?.toLocaleString()}
                    {/* <span className='red-text'>-1.3%</span> */}
                  </h6>
                </div>
              </div>
            </div>
            <div className="single-box">
              <div className="main-head">
                <h6>decryptions</h6>
              </div>
              <div className="inside-box">
                <div className="upper-text">
                  <div className="inner-text">
                    <p>Amount Decrypted</p>
                    <h6>
                      ${Number.isNaN(Number(decryptData?.overallTotal))
                        ? "0"
                        : Number(decryptData?.overallTotal?.toFixed(4))?.toLocaleString()}
                      {/* <span className='red-text'>-1.3%</span> */}
                    </h6>
                  </div>
                  <div class="dropdown">
                    <button class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                        <path d="M16 10.1437L12 14.1437L8 10.1437" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </button>
                    <ul class="dropdown-menu">
                      {/* <div className="inside-coins">
                        <div className="single-coin">
                          <div className="left-text">
                            <img src="\assets\coins\eth.svg" alt="img" className='img-fluid' />
                            <p>ETH</p>
                          </div>
                          <h6> {Number.isNaN(Number(encryptdecrypt?.decryptData?.[0]?.overallTotal))
                            ? "0"
                            : Number(encryptdecrypt?.decryptData?.[0]?.overallTotal?.toFixed(2))?.toLocaleString()}ETH</h6>
                        </div>
                        <div className="single-coin">
                          <div className="left-text">
                            <img src="\assets\coins\weth.svg" alt="img" className='img-fluid' />
                            <p>WETH</p>
                          </div>
                          <h6> {Number.isNaN(Number(encryptdecrypt?.decryptData?.[0]?.overallTotal))
                            ? "0"
                            : Number(encryptdecrypt?.decryptData?.[0]?.overallTotal?.toFixed(2))?.toLocaleString()}WETH</h6>
                        </div>
                        <div className="single-coin">
                          <div className="left-text">
                            <img src="\assets\coins\btc.svg" alt="img" className='img-fluid' />
                            <p>WBTC</p>
                          </div>
                          <h6> {Number.isNaN(Number(encryptdecrypt?.decryptData?.[0]?.assets?.[2]?.totalAmount))
                            ? "0"
                            : Number(encryptdecrypt?.decryptData?.[0]?.assets?.[2]?.totalAmount)}WBTC</h6>
                        </div>
                        <div className="single-coin">
                          <div className="left-text">
                            <img src="\assets\coins\usdt.svg" alt="img" className='img-fluid' />
                            <p>USDT</p>
                          </div>
                          <h6> {Number.isNaN(Number(encryptdecrypt?.decryptData?.[0]?.overallTotal))
                            ? "0"
                            : Number(encryptdecrypt?.decryptData?.[0]?.overallTotal?.toFixed(2))?.toLocaleString()}USDT</h6>
                        </div>
                        <div className="single-coin">
                          <div className="left-text">
                            <img src="\assets\coins\usdc.svg" alt="img" className='img-fluid' />
                            <p>USDC</p>
                          </div>
                          <h6> {Number.isNaN(Number(encryptdecrypt?.decryptData?.[0]?.overallTotal))
                            ? "0"
                            : Number(encryptdecrypt?.decryptData?.[0]?.overallTotal?.toFixed(2))?.toLocaleString()}USDC</h6>
                        </div>
                      </div> */}
                      <div className="inside-coins">
                        <div className="single-coin">
                          <div className="left-text">
                            <img src="/assets/coins/weth.svg" alt="img" className='img-fluid' />
                            <p>WETH</p>
                          </div>
                          <h6>{formatAmount(decryptData?.assets?.find(asset => asset.asset === 'ethereum')?.totalAmount)} WETH</h6>
                        </div>
                        <div className="single-coin">
                          <div className="left-text">
                            <img src="\assets\coins\btc.svg" alt="img" className='img-fluid' />
                            <p>WBTC</p>
                          </div>
                          <h6>{formatAmount(decryptData?.assets?.find(asset => asset.asset === 'wrapped-bitcoin')?.totalAmount)} WBTC</h6>
                        </div>
                        <div className="single-coin">
                          <div className="left-text">
                            <img src="/assets/coins/usdt.svg" alt="img" className='img-fluid' />
                            <p>USDT</p>
                          </div>
                          <h6>{formatAmount(decryptData?.assets?.find(asset => asset.asset === 'tether')?.totalAmount)} USDT</h6>
                        </div>
                        <div className="single-coin">
                          <div className="left-text">
                            <img src="/assets/coins/usdc.svg" alt="img" className='img-fluid' />
                            <p>USDC</p>
                          </div>
                          <h6>{formatAmount(decryptData?.assets?.find(asset => asset.asset === 'usd-coin')?.totalAmount)} USDC</h6>
                        </div>
                        <div className="single-coin">
                          <div className="left-text">
                            <img src="/assets/coins/dop.svg" alt="img" className='img-fluid' />
                            <p>DOP</p>
                          </div>
                          <h6>{formatAmount(decryptData?.assets?.find(asset => asset.asset === 'data-ownership-protocol')?.totalAmount)} DOP</h6>
                        </div>
                        <div className="single-coin">
                          <div className="left-text">
                            <img src="/assets/coins/matic.svg" alt="img" className='img-fluid' />
                            <p>MATIC</p>
                          </div>
                          <h6>{formatAmount(decryptData?.assets?.find(asset => asset.asset === 'matic-network')?.totalAmount)} MATIC</h6>
                        </div>
                        <div className="single-coin">
                          <div className="left-text">
                            <img src="/assets/coins/matic.svg" alt="img" className='img-fluid' />
                            <p>POL</p>
                          </div>
                          <h6>{formatAmount(decryptData?.assets?.find(asset => asset.asset === 'polygon-ecosystem-token')?.totalAmount)} POL</h6>
                        </div>
                        <div className="single-coin">
                          <div className="left-text">
                            <img src="/assets/coins/pepe.svg" alt="img" className='img-fluid' />
                            <p>PEPE</p>
                          </div>
                          <h6>{formatAmount(decryptData?.assets?.find(asset => asset.asset === 'pepe')?.totalAmount)} PEPE</h6>
                        </div>
                        <div className="single-coin">
                          <div className="left-text">
                            <img src="/assets/coins/link.svg" alt="img" className='img-fluid' />
                            <p>LINK</p>
                          </div>
                          <h6>{formatAmount(decryptData?.assets?.find(asset => asset.asset === 'chainlink')?.totalAmount)} LINK</h6>
                        </div>
                        <div className="single-coin">
                          <div className="left-text">
                            <img src="/assets/coins/uni.svg" alt="img" className='img-fluid' />
                            <p>UNI</p>
                          </div>
                          <h6>{formatAmount(decryptData?.assets?.find(asset => asset.asset === 'uniswap')?.totalAmount)} UNI</h6>
                        </div>
                        <div className="single-coin">
                          <div className="left-text">
                            <img src="/assets/coins/rndr.svg" alt="img" className='img-fluid' />
                            <p>RNDR</p>
                          </div>
                          <h6>{formatAmount(decryptData?.assets?.find(asset => asset.asset === 'render-token')?.totalAmount)} RNDR</h6>
                        </div>
                        <div className="single-coin">
                          <div className="left-text">
                            <img src="/assets/coins/shib.svg" alt="img" className='img-fluid' />
                            <p>SHIB</p>
                          </div>
                          <h6>{formatAmount(decryptData?.assets?.find(asset => asset.asset === 'shiba-inu')?.totalAmount)} SHIB</h6>
                        </div>
                        <div className="single-coin">
                          <div className="left-text">
                            <img src="/assets/coins/ldo.svg" alt="img" className='img-fluid' />
                            <p>LDO</p>
                          </div>
                          <h6>{formatAmount(decryptData?.assets?.find(asset => asset.asset === 'lido-dao')?.totalAmount)} LDO</h6>
                        </div>
                        <div className="single-coin">
                          <div className="left-text">
                            <img src="/assets/coins/floki.svg" alt="img" className='img-fluid' />
                            <p>FLOKI</p>
                          </div>
                          <h6>{formatAmount(decryptData?.assets?.find(asset => asset.asset === 'floki')?.totalAmount)} FLOKI</h6>
                        </div>
                        <div className="single-coin">
                          <div className="left-text">
                            <img src="/assets/coins/dai.svg" alt="img" className='img-fluid' />
                            <p>DAI</p>
                          </div>
                          <h6>{formatAmount(decryptData?.assets?.find(asset => asset.asset === 'dai')?.totalAmount)} DAI</h6>
                        </div>
                        <div className="single-coin">
                          <div className="left-text">
                            <img src="/assets/coins/aave.svg" alt="img" className='img-fluid' />
                            <p>AAVE</p>
                          </div>
                          <h6>{formatAmount(decryptData?.assets?.find(asset => asset.asset === 'aave')?.totalAmount)} AAVE</h6>
                        </div>
                        <div className="single-coin">
                          <div className="left-text">
                            <img src="/assets/coins/ens.svg" alt="img" className='img-fluid' />
                            <p>ENS</p>
                          </div>
                          <h6>{formatAmount(decryptData?.assets?.find(asset => asset.asset === 'ethereum-name-service')?.totalAmount)} ENS</h6>
                        </div>
                        <div className="single-coin">
                          <div className="left-text">
                            <img src="/assets/coins/chz.svg" alt="img" className='img-fluid' />
                            <p>CHZ</p>
                          </div>
                          <h6>{formatAmount(decryptData?.assets?.find(asset => asset.asset === 'chiliz')?.totalAmount)} CHZ</h6>
                        </div>
                        <div className="single-coin">
                          <div className="left-text">
                            <img src="/assets/coins/mana.svg" alt="img" className='img-fluid' />
                            <p>MANA</p>
                          </div>
                          <h6>{formatAmount(decryptData?.assets?.find(asset => asset.asset === 'decentraland')?.totalAmount)} MANA</h6>
                        </div>
                      </div>
                    </ul>
                  </div>
                </div>
                <div className="bottom-text">
                  <p>No. of  Decrypts</p>
                  <h6>
                    {/* {decryptData?.totalCount} */}
                    {Number.isNaN(Number(decryptData?.totalCount))
                      ? "0"
                      : Number(decryptData?.totalCount?.toFixed(2))?.toLocaleString()}
                    {/* <span className='red-text'>-1.3%</span> */}
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div className="reward-part">
            <div className="single-reward">
              <div className="left-side">
                <div className="img-area">
                  <img src="\assets\pointsearned.svg" alt="img" className='img-fluid' />
                </div>
                <div className="text">
                  <p>Points Earned</p>
                  <h6>
                    {Number.isNaN(Number(userspoints?.totalPoints))
                      ? "0"
                      : Number(userspoints?.totalPoints?.toFixed(2))?.toLocaleString()}
                    {/* <span className='red-text'>-1.3%</span>*/}
                  </h6>
                </div>
              </div>
              <div class="dropdown">
                <button class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                    <rect x="24.5" y="24.1437" width="24" height="24" rx="12" transform="rotate(-180 24.5 24.1437)" fill="white" fill-opacity="0.1" />
                    <path d="M8.5 14.1437L12.5 10.1437L16.5 14.1437" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </button>
                <ul class="dropdown-menu">
                  <div className="inside-coins">
                    <div className="single-coin">
                      <div className="left-text">
                        <p>Action Points</p>
                      </div>
                      <h6>{Number.isNaN(Number(userspoints?.totalActionPoints))
                        ? "0"
                        : Number(userspoints?.totalActionPoints?.toFixed(2))?.toLocaleString()}</h6>
                    </div>
                    <div className="single-coin">
                      <div className="left-text">
                        <p>Referral Points</p>
                      </div>
                      <h6>{Number.isNaN(Number(userspoints?.totalReferralPoints))
                        ? "0"
                        : Number(userspoints?.totalReferralPoints?.toFixed(2))?.toLocaleString()}</h6>
                    </div>
                    <div className="single-coin">
                      <div className="left-text">
                        <p>Staking Points </p>
                      </div>
                      <h6>{Number.isNaN(Number(userspoints?.totalStakingPoints))
                        ? "0"
                        : Number(userspoints?.totalStakingPoints?.toFixed(2))?.toLocaleString()}</h6>
                    </div>

                  </div>
                </ul>
              </div>
            </div>
            <div className="single-reward">
              <div className="left-side">
                <div className="img-area">
                  <img src="\assets\totalrefferal.svg" alt="img" className='img-fluid' />
                </div>
                <div className="text">
                  <p>Total Referrals</p>
                  <h6>
                    {Number.isNaN(Number(userspoints?.referalsCount))
                      ? "0"
                      : Number(userspoints?.referalsCount?.toFixed(2))?.toLocaleString()}
                    {/* <span className='red-text'>-1.3%</span>*/}
                  </h6>
                </div>
              </div>
            </div>
            <div className="single-reward">
              <div className="left-side">
                <div className="img-area">
                  <img src="\assets\totalcountries.svg" alt="img" className='img-fluid' />
                </div>
                <div className="text">
                  <p>Total Countries</p>
                  <h6>{countries?.countries}
                    {/* <span className='red-text'>-1.3%</span> */}
                  </h6>
                </div>
              </div>
            </div>
            <div className="single-reward">
              <div className="left-side">
                <div className="img-area">
                  <img src="\assets\badgeearned.svg" alt="img" className='img-fluid' />
                </div>
                <div className="text">
                  <p>Badges Earned</p>
                  <h6>
                    {Number.isNaN(Number(sumofbadges))
                      ? "0"
                      : Number(sumofbadges?.toFixed(2))?.toLocaleString()}
                    {/* <span className='red-text'>-1.3%</span>*/}
                  </h6>
                </div>
              </div>
              <div class="dropdown dropdownbadges">
                <button class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                    <rect x="24.5" y="24.1437" width="24" height="24" rx="12" transform="rotate(-180 24.5 24.1437)" fill="white" fill-opacity="0.1" />
                    <path d="M8.5 14.1437L12.5 10.1437L16.5 14.1437" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </button>
                <ul class="dropdown-menu">
                  <div className="inside-coins">
                    <div className="single-coin">
                      <div className="left-text">
                        <img src="\assets\badges\telgram.svg" alt="img" className='img-fluid' />
                        <p>Join Telegram</p>
                      </div>
                      <h6>
                        {Number.isNaN(Number(usersdata?.telegramFollowers))
                          ? "0"
                          : Number(usersdata?.telegramFollowers?.toFixed(2))?.toLocaleString()}
                        {/* <span className='red-text'>-1.3%</span>*/}
                      </h6>
                    </div>
                    <div className="single-coin">
                      <div className="left-text">
                        <img src="\assets\badges\twitter.svg" alt="img" className='img-fluid' />
                        <p>Follow X</p>
                      </div>
                      <h6>
                        {Number.isNaN(Number(usersdata?.twitterFollowers))
                          ? "0"
                          : Number(usersdata?.twitterFollowers?.toFixed(2))?.toLocaleString()}
                        {/* <span className='red-text'>-1.3%</span>*/}
                      </h6>
                    </div>
                    <div className="single-coin">
                      <div className="left-text">
                        <img src="\assets\badges\discord.svg" alt="img" className='img-fluid' />
                        <p>Follow Discord</p>
                      </div>
                      <h6>
                        {Number.isNaN(Number(usersdata?.discordFollowers))
                          ? "0"
                          : Number(usersdata?.discordFollowers?.toFixed(2))?.toLocaleString()}
                        {/* <span className='red-text'>-1.3%</span>*/}
                      </h6>
                    </div>
                    <div className="single-coin">
                      <div className="left-text">
                        <img src="\assets\badges\zkkyc.svg" alt="img" className='img-fluid' />
                        <p>Zk KYC</p>
                      </div>

                      <h6>
                        {Number.isNaN(Number(usersdata?.kycVerified))
                          ? "0"
                          : Number(usersdata?.kycVerified?.toFixed(2))?.toLocaleString()}
                        {/* <span className='red-text'>-1.3%</span>*/}
                      </h6>
                    </div>
                    <div className="single-coin">
                      <div className="left-text">
                        <img src="\assets\badges\email.svg" alt="img" className='img-fluid' />
                        <p>Email verification</p>
                      </div>

                      <h6>
                        {Number.isNaN(Number(usersdata?.emailVerified))
                          ? "0"
                          : Number(usersdata?.emailVerified?.toFixed(2))?.toLocaleString()}
                        {/* <span className='red-text'>-1.3%</span>*/}
                      </h6>
                    </div>
                    <div className="single-coin">
                      <div className="left-text">
                        <img src="\assets\badges\e1.svg" alt="img" className='img-fluid' />
                        <p>Encrypt $10,000</p>
                      </div>

                      <h6>
                        {Number.isNaN(Number(usersencrypt?.encrypt_10000))
                          ? "0"
                          : Number(usersencrypt?.encrypt_10000?.toFixed(2))?.toLocaleString()}
                        {/* <span className='red-text'>-1.3%</span>*/}
                      </h6>
                    </div>
                    <div className="single-coin">
                      <div className="left-text">
                        <img src="\assets\badges\e2.svg" alt="img" className='img-fluid' />
                        <p>Encrypt $20,000</p>
                      </div>

                      <h6>
                        {Number.isNaN(Number(usersencrypt?.encrypt_20000))
                          ? "0"
                          : Number(usersencrypt?.encrypt_20000?.toFixed(2))?.toLocaleString()}
                        {/* <span className='red-text'>-1.3%</span>*/}
                      </h6>
                    </div>
                    <div className="single-coin">
                      <div className="left-text">
                        <img src="\assets\badges\e3.svg" alt="img" className='img-fluid' />
                        <p>Encrypt $50,000</p>
                      </div>

                      <h6>
                        {Number.isNaN(Number(usersencrypt?.encrypt_50000))
                          ? "0"
                          : Number(usersencrypt?.encrypt_50000?.toFixed(2))?.toLocaleString()}
                        {/* <span className='red-text'>-1.3%</span>*/}
                      </h6>
                    </div>
                    <div className="single-coin">
                      <div className="left-text">
                        <img src="\assets\badges\e4.svg" alt="img" className='img-fluid' />
                        <p>Encrypt $100,000</p>
                      </div>

                      <h6>
                        {Number.isNaN(Number(usersencrypt?.encrypt_100000))
                          ? "0"
                          : Number(usersencrypt?.encrypt_100000?.toFixed(2))?.toLocaleString()}
                        {/* <span className='red-text'>-1.3%</span>*/}
                      </h6>
                    </div>
                    <div className="single-coin">
                      <div className="left-text">
                        <img src="\assets\badges\e5.svg" alt="img" className='img-fluid' />
                        <p>Encrypt $500,000</p>
                      </div>

                      <h6>
                        {Number.isNaN(Number(usersencrypt?.encrypt_500000))
                          ? "0"
                          : Number(usersencrypt?.encrypt_500000?.toFixed(2))?.toLocaleString()}
                        {/* <span className='red-text'>-1.3%</span>*/}
                      </h6>
                    </div>
                    <div className="single-coin">
                      <div className="left-text">
                        <img src="\assets\badges\e6.svg" alt="img" className='img-fluid' />
                        <p>Encrypt $1,000,000</p>
                      </div>

                      <h6>
                        {Number.isNaN(Number(usersencrypt?.encrypt_1000000))
                          ? "0"
                          : Number(usersencrypt?.encrypt_1000000?.toFixed(2))?.toLocaleString()}
                        {/* <span className='red-text'>-1.3%</span>*/}
                      </h6>
                    </div>
                    <div className="single-coin">
                      <div className="left-text">
                        <img src="\assets\badges\s1.svg" alt="img" className='img-fluid' />
                        <p>50 Sends</p>
                      </div>

                      <h6>
                        {Number.isNaN(Number(userssends?.send_50))
                          ? "0"
                          : Number(userssends?.send_50?.toFixed(2))?.toLocaleString()}
                        {/* <span className='red-text'>-1.3%</span>*/}
                      </h6>
                    </div>
                    <div className="single-coin">
                      <div className="left-text">
                        <img src="\assets\badges\s2.svg" alt="img" className='img-fluid' />
                        <p>100 Sends</p>
                      </div>

                      <h6>
                        {Number.isNaN(Number(userssends?.send_100))
                          ? "0"
                          : Number(userssends?.send_100?.toFixed(2))?.toLocaleString()}
                        {/* <span className='red-text'>-1.3%</span>*/}
                      </h6>
                    </div>
                    <div className="single-coin">
                      <div className="left-text">
                        <img src="\assets\badges\s3.svg" alt="img" className='img-fluid' />
                        <p>200 Sends</p>
                      </div>

                      <h6>
                        {Number.isNaN(Number(userssends?.send_200))
                          ? "0"
                          : Number(userssends?.send_200?.toFixed(2))?.toLocaleString()}
                        {/* <span className='red-text'>-1.3%</span>*/}
                      </h6>
                    </div>
                    <div className="single-coin">
                      <div className="left-text">
                        <img src="\assets\badges\s4.svg" alt="img" className='img-fluid' />
                        <p>500 Sends</p>
                      </div>
                      <h6>
                        {Number.isNaN(Number(userssends?.send_500))
                          ? "0"
                          : Number(userssends?.send_500?.toFixed(2))?.toLocaleString()}
                        {/* <span className='red-text'>-1.3%</span>*/}
                      </h6>
                    </div>
                    <div className="single-coin">
                      <div className="left-text">
                        <img src="\assets\badges\ds1.svg" alt="img" className='img-fluid' />
                        <p>DOP Prince</p>
                      </div>
                      <h6>0</h6>
                    </div>
                    <div className="single-coin">
                      <div className="left-text">
                        <img src="\assets\badges\ds2.svg" alt="img" className='img-fluid' />
                        <p>DOP Queen</p>
                      </div>
                      <h6>0</h6>
                    </div>
                    <div className="single-coin">
                      <div className="left-text">
                        <img src="\assets\badges\ds3.svg" alt="img" className='img-fluid' />
                        <p>DOP King</p>
                      </div>
                      <h6>0</h6>
                    </div>
                    <div className="single-coin">
                      <div className="left-text">
                        <img src="\assets\badges\ds4.svg" alt="img" className='img-fluid' />
                        <p>DOP Treasure</p>
                      </div>
                      <h6>0</h6>
                    </div>
                  </div>
                </ul>
              </div>
            </div>
          </div>
          <Datatable page={page} pageCount={pageCount} handlePageChange={handlePageChange} startIndex={startIndex} endIndex={endIndex} itemsPerPage={itemsPerPage} loader={loadertransaction} transactions={transactions} />
        </div>
      </section >
    </>
  )
}

export default Banner