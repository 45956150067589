import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Landing from './components/landing/Landing';
import Login from './components/Login/Login';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        style={{ fontSize: '16px', zIndex: "9999999999999999" }}
        draggable
        pauseOnHover
        theme="colored"
      />
      <Router>
        <Routes>
          <Route
            exact
            path='/landing'
            element={<Landing />}
          />
          <Route
            exact
            path='/'
            element={<Login />}
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
