// import React from 'react'
// import './header.scss'
// import { Link } from 'react-router-dom';
// import { useNavigate } from 'react-router-dom/dist';
// import { Dropdown } from 'react-bootstrap';
// import { useNetworks } from '../../NetworkContext';

// const Header = () => {
//   const { selectedNetworks, handleCheckboxChange } = useNetworks();
//   const navigate = useNavigate();

//   const handleLogout = () => {
//     localStorage.removeItem('accessToken');
//     localStorage.removeItem('refreshToken');
//     navigate('/');
//   };
//   const getSelectedNetworksText = () => {
//     const selected = Object.entries(selectedNetworks)
//       .filter(([_, isSelected]) => isSelected)
//       .map(([network, _]) => network)
//       .join(', ');
//     return selected || 'Select Network';
//   };


//   return (
//     <section className="mainheader">
//       <div className="custom-container">
//         <div className="innerheader">
//           <Link
//           // to="/"s
//           >
//             <img src="\logo.svg" alt="logoimg" className="logoimg" />
//           </Link>
//           <div className="twice-btns">
//                <div className="dropdown">
//                 <button
//                   className="dropdown-toggle"
//                   type="button"
//                   data-bs-toggle="dropdown"
//                   data-bs-auto-close="outside"
//                   aria-expanded="false"
//                 >
//                    Ethereum
//                   <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
//                     <path d="M12 8L9 11L6 8" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
//                   </svg>
//                 </button>
//                 <ul className="dropdown-menu">
//                   <li>
//                     <a
//                       className="dropdown-item"
//                       href="#"
                      
//                     >
//                       <div className="mycheckbox">
//                         <label className="innercheckbox" htmlFor="firstinput">
//                           <input
//                             type="checkbox"
//                             name="color"
//                             id="firstinput"
//                             readOnly
//                           />
//                           <span className="innercheckmark"></span>
//                         </label>
//                         <label htmlFor="firstinput" className="innercheckboxpara"><img src="\assets\dropicons\eth.svg" alt="img" className='img-fluid' />ETHEREUM</label>
//                       </div>
//                     </a>
//                   </li>
//                   <li>
//                     <a
//                       className="dropdown-item"
//                       href="#"
                      
//                     >
//                       <div className="mycheckbox">
//                         <label className="innercheckbox" htmlFor="secinput">
//                           <input
//                             type="checkbox"
//                             name="color"
//                             id="secinput"
//                             readOnly
//                           />
//                           <span className="innercheckmark"></span>
//                         </label>
//                         <label htmlFor="secinput" className="innercheckboxpara"><img src="\assets\dropicons\poly.svg" alt="img" className='img-fluid' />POLYGON</label>
//                       </div>
//                     </a>
//                   </li>
//                   <li>
//                     <a
//                       className="dropdown-item"
//                       href="#"
                      
//                     >
//                       <div className="mycheckbox">
//                         <label className="innercheckbox" htmlFor="thirdinput">
//                           <input
//                             type="checkbox"
//                             name="color"
//                             id="thirdinput"
//                             readOnly
//                           />
//                           <span className="innercheckmark"></span>
//                         </label>
//                         <label htmlFor="thirdinput" className="innercheckboxpara"><img src="\assets\dropicons\xlayer.svg" alt="img" className='img-fluid' />X LAYER</label>
//                       </div>
//                     </a>
//                   </li>
                
//                 </ul>
//               </div>
//             <button onClick={handleLogout} className="btn-launch">
//               <h6>Logout</h6>
//               <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
//                 <path d="M8.49992 10.625L10.6249 8.50001M10.6249 8.50001L8.49992 6.37501M10.6249 8.50001H2.83325M2.83325 5.13402V5.10015C2.83325 4.30674 2.83325 3.90974 2.98766 3.6067C3.12348 3.34014 3.34005 3.12357 3.60661 2.98775C3.90965 2.83334 4.30665 2.83334 5.10006 2.83334H11.9001C12.6935 2.83334 13.0896 2.83334 13.3927 2.98775C13.6592 3.12357 13.8765 3.34014 14.0123 3.6067C14.1666 3.90944 14.1666 4.30596 14.1666 5.09782V11.9025C14.1666 12.6944 14.1666 13.0903 14.0123 13.3931C13.8765 13.6597 13.6592 13.8766 13.3927 14.0124C13.0899 14.1667 12.694 14.1667 11.9021 14.1667H5.09773C4.30587 14.1667 3.90935 14.1667 3.60661 14.0124C3.34005 13.8766 3.12348 13.6594 2.98766 13.3929C2.83325 13.0898 2.83325 12.6934 2.83325 11.9V11.8646" stroke="#FF0000" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
//               </svg>
//             </button>
//           </div>
//         </div>
//       </div>
//     </section>
//   )
// }

// export default Header


import React from 'react';
import './header.scss';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom/dist';
import { Dropdown } from 'react-bootstrap';
import { useNetworks } from '../../NetworkContext';

const Header = () => {
  const { selectedNetworks, handleCheckboxChange } = useNetworks();
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    navigate('/');
  };

  // const getSelectedNetworksText = () => {
  //   
      
  //   
  //   const selected = Object.entries(selectedNetworks)
  //     .filter(([_, isSelected]) => isSelected)
  //     .map(([network, _]) => network === 'okx' ? 'X Layer' : network)  
  //     .join(', ');
  
  //   return selected || "Select Networks"; 
  // };

  const getSelectedNetworksText = () => {
    if (!selectedNetworks.Ethereum && !selectedNetworks.Polygon && !selectedNetworks.okx) {
      selectedNetworks.Ethereum = true;
    }
    const selected = Object.entries(selectedNetworks)
      .filter(([_, isSelected]) => isSelected)
      .map(([network, _]) => network === 'okx' ? 'X Layer' : network)
      .join(', ');
    return selected || 'Ethereum';
  };
  



  return (
    <section className="mainheader">
      <div className="custom-container">
        <div className="innerheader">
          <Link>
            <img src="\logo.svg" alt="logoimg" className="logoimg" />
          </Link>
          <div className="twice-btns">
               <div className="dropdown">
                <button
                  className="dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="outside"
                  aria-expanded="false"
                >
                   {getSelectedNetworksText()}
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                    <path d="M12 8L9 11L6 8" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </button>
                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item">
                      <div className="mycheckbox">
                        <label className="innercheckbox" htmlFor="firstinput">
                          <input
                            type="checkbox"
                            name="Ethereum"
                            id="firstinput"
                            checked={selectedNetworks.Ethereum}
                            onChange={() => handleCheckboxChange('Ethereum')}
                            readOnly
                          />
                          <span className="innercheckmark"></span>
                        </label>
                        <label htmlFor="firstinput" className="innercheckboxpara">
                          <img src="\assets\dropicons\eth.svg" alt="Ethereum" className='img-fluid' />ETHEREUM
                        </label>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item">
                      <div className="mycheckbox">
                        <label className="innercheckbox" htmlFor="secinput">
                          <input
                            type="checkbox"
                            name="Polygon"
                            id="secinput"
                            checked={selectedNetworks.Polygon}
                            onChange={() => handleCheckboxChange('Polygon')}
                            readOnly
                          />
                          <span className="innercheckmark"></span>
                        </label>
                        <label htmlFor="secinput" className="innercheckboxpara">
                          <img src="\assets\dropicons\poly.svg" alt="Polygon" className='img-fluid' />POLYGON
                        </label>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item">
                      <div className="mycheckbox">
                        <label className="innercheckbox" htmlFor="thirdinput">
                          <input
                            type="checkbox"
                            name="okx"
                            id="thirdinput"
                            checked={selectedNetworks.okx}
                            onChange={() => handleCheckboxChange('okx')}
                            readOnly
                          />
                          <span className="innercheckmark"></span>
                        </label>
                        <label htmlFor="thirdinput" className="innercheckboxpara">
                          <img src="\assets\dropicons\xlayer.svg" alt="okx" className='img-fluid' />X LAYER
                        </label>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            <button onClick={handleLogout} className="btn-launch">
              <h6>Logout</h6>
              <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                <path d="M8.49992 10.625L10.6249 8.50001M10.6249 8.50001L8.49992 6.37501M10.6249 8.50001H2.83325M2.83325 5.13402V5.10015C2.83325 4.30674 2.83325 3.90974 2.98766 3.6067C3.12348 3.34014 3.34005 3.12357 3.60661 2.98775C3.90965 2.83334 4.30665 2.83334 5.10006 2.83334H11.9001C12.6935 2.83334 13.0896 2.83334 13.3927 2.98775C13.6592 3.12357 13.8765 3.34014 14.0123 3.6067C14.1666 3.90944 14.1666 4.30596 14.1666 5.09782V11.9025C14.1666 12.6944 14.1666 13.0903 14.0123 13.3931C13.8765 13.6597 13.6592 13.8766 13.3927 14.0124C13.0899 14.1667 12.694 14.1667 11.9021 14.1667H5.09773C4.30587 14.1667 3.90935 14.1667 3.60661 14.0124C3.34005 13.8766 3.12348 13.6594 2.98766 13.3929C2.83325 13.0898 2.83325 12.6934 2.83325 11.9V11.8646" stroke="#FF0000" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Header
