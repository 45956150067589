import React from 'react'
import "./datatable.scss"
import ReactPaginate from 'react-paginate'
import moment from "moment";

const Datatable = ({ transactions, loader, handlePageChange, startIndex, endIndex, itemsPerPage, page, pageCount, }) => {
    console.log(transactions, 'trancss');
    return (
        <>
            <section className="datatable-section">
                <div className="main-heading">
                    <h6>latest transactions</h6>
                </div>
                <div className="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>type</th>
                                <th>Asset</th>
                                <th>AMOUNT</th>
                                <th>VALUE IN USD</th>
                                <th>TX Hash</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loader ? (
                                <div className="text-white text-center">Loading...</div>
                            ) : (
                                <>
                                    {transactions?.length > 0 ? (
                                        transactions?.map((item, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <div className="twice-text">
                                                        {item?.createdAt
                                                            ? moment(item?.createdAt).utc().format('DD/MM/YYYY hh:mm')
                                                            : '0'}
                                                    </div>
                                                </td>
                                                <td>{item?.type}</td>
                                                <td>{item?.asset}</td>
                                                <td>
                                                    <p className="para">
                                                        {item?.asset === 'ethereum' ? (
                                                            <img
                                                                src="/assets/coins/weth.svg"
                                                                alt="img"
                                                                className="img-fluid"
                                                            />
                                                        ) : item?.asset === 'usd-coin' ? (
                                                            <img
                                                                src="/assets/coins/usdc.svg"
                                                                alt="img"
                                                                className="img-fluid"
                                                            />
                                                        ) : item?.asset === 'tether' ? (
                                                            <img
                                                                src="/assets/coins/usdt.svg"
                                                                alt="img"
                                                                className="img-fluid"
                                                            />
                                                        ) : item?.asset === 'wrapped-bitcoin' ? (
                                                            <img
                                                                src="\assets\coins\btc.svg"
                                                                alt="img"
                                                                className="img-fluid"
                                                            />
                                                        ) : (
                                                            ''
                                                        )}
                                                        {item?.asset === 'ethereum'
                                                            ? 'WETH'
                                                            : item?.asset === 'usd-coin'
                                                                ? 'USDC'
                                                                : item?.asset === 'tether'
                                                                    ? 'USDT'
                                                                    : item?.asset === 'wrapped-bitcoin'
                                                                        ? 'WBTC'
                                                                        : ''}
                                                    </p>
                                                </td>
                                                <td>
                                                    {item?.asset === 'ethereum'
                                                        ? item?.usdtAmount.toFixed(3)
                                                        : item?.amount.toFixed()}
                                                </td>
                                                <td>
                                                    <p className="para">
                                                        {item?.txHash?.slice(0, 3)}...
                                                        {item?.txHash?.slice(
                                                            item?.txHash?.length - 3,
                                                            item?.txHash?.length
                                                        )}
                                                        <a
                                                            href={
                                                                item?.chain === 'ethereum'
                                                                    ? `https://etherscan.io/tx/${item?.txHash}`
                                                                    : item?.chain === 'polygon'
                                                                        ? `https://polygonscan.com/tx/${item?.txHash}`
                                                                        : item?.chain === 'okx'
                                                                            ? `https://www.okx.com/web3/explorer/xlayer/tx/${item?.txHash}`
                                                                            : '#'
                                                            }
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="15"
                                                                height="15"
                                                                viewBox="0 0 15 15"
                                                                fill="none"
                                                            >
                                                                <path
                                                                    d="M7.91671 6.56034L12.7 1.77701"
                                                                    stroke="white"
                                                                    strokeWidth="1.5"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                                <path
                                                                    d="M13.1667 4.11033V1.31033H10.3667"
                                                                    stroke="white"
                                                                    strokeWidth="1.5"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                                <path
                                                                    d="M6.75005 1.31033H5.58338C2.66671 1.31033 1.50005 2.477 1.50005 5.39367V8.89367C1.50005 11.8103 2.66671 12.977 5.58338 12.977H9.08338C12 12.977 13.1667 11.8103 13.1667 8.89367V7.727"
                                                                    stroke="white"
                                                                    strokeWidth="1.5"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                            </svg>
                                                        </a>
                                                    </p>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="6">No data available</td>
                                        </tr>
                                    )}
                                </>
                            )}
                        </tbody>

                    </table>
                    <div className="footer-content">
                        <div className="left-f">
                            <h6>{startIndex}-{endIndex} OF {pageCount}</h6>
                        </div>
                        <div className="right-f">
                            <ReactPaginate
                                previousLabel={
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="15"
                                        height="15"
                                        viewBox="0 0 15 15"
                                        fill="none"
                                    >
                                        <path
                                            d="M10 13L5 8L10 3"
                                            stroke="#fff"
                                            stroke-width="1.5"
                                            stroke-miterlimit="10"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg>
                                }
                                nextLabel={
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="15"
                                        height="15"
                                        viewBox="0 0 15 15"
                                        fill="none"
                                    >
                                        <path
                                            d="M5 13L10 8L5 3"
                                            stroke="#fff"
                                            stroke-width="1.5"
                                            stroke-miterlimit="10"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg>
                                }
                                pageClassName="page-item border border-white text-white bg-white"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                pageCount={Math.ceil(pageCount / itemsPerPage)}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageChange}
                                containerClassName="pagination"
                                activeClassName="active"
                                forcePage={page}

                            ></ReactPaginate>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Datatable
