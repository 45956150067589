// import React, { createContext, useContext, useState } from 'react';

// const NetworkContext = createContext();

// export const useNetworks = () => useContext(NetworkContext);

// export const NetworkProvider = ({ children }) => {
//     const [selectedNetworks, setSelectedNetworks] = useState({
//         Ethereum: false,
//         Polygon: false,
//         okx: false,
//     });
//     const handleCheckboxChange = (network) => {
//         setSelectedNetworks((prevState) => {
//             if (prevState[network]) {
//                 return {
//                     Ethereum: false,
//                     Polygon: false,
//                     okx: false,
//                 };
//             }
//             return {
//                 Ethereum: network === 'Ethereum',
//                 Polygon: network === 'Polygon',
//                 okx: network === 'okx',
//             };
//         });
//     };

//     return (
//         <NetworkContext.Provider value={{ selectedNetworks, handleCheckboxChange }}>
//             {children}
//         </NetworkContext.Provider>
//     );
// };


import React, { createContext, useContext, useState } from 'react';

const NetworkContext = createContext();

export const useNetworks = () => useContext(NetworkContext);

export const NetworkProvider = ({ children }) => {
    const [selectedNetworks, setSelectedNetworks] = useState({
        Ethereum: false,
        Polygon: false,
        okx: false,
    });

    const handleCheckboxChange = (network) => {
        setSelectedNetworks((prevState) => ({
            ...prevState,
            [network]: !prevState[network],  // Toggle the selected network's value
        }));
    };

    return (
        <NetworkContext.Provider value={{ selectedNetworks, handleCheckboxChange }}>
            {children}
        </NetworkContext.Provider>
    );
};
