export const SUPPORTED_CHAINS = {
    POLYGON: 137,
    ETHEREUM: 1,
    Xlayer:196
}

export const CHAIN_CONFIG = {
    [SUPPORTED_CHAINS.POLYGON]: {
        chainId: 137,
        nodes: [
            process.env.REACT_APP_POLYGON_NODE_1,
            process.env.REACT_APP_POLYGON_NODE_2,
            process.env.REACT_APP_POLYGON_NODE_3
        ]
    },
    [SUPPORTED_CHAINS.ETHEREUM]: {
        chainId: 1,
        nodes: [
            process.env.REACT_APP_ETH_NODE_1,
            process.env.REACT_APP_ETH_NODE_2,
            process.env.REACT_APP_ETH_NODE_3
        ]
    },
    [SUPPORTED_CHAINS.Xlayer]: {
        chainId: 196,
        nodes: [
            process.env.REACT_APP_OKX_NODE_1,
            process.env.REACT_APP_OKX_NODE_2,
            process.env.REACT_APP_OKX_NODE_3
        ]
    }
}
